import {
  Button,
  Checkbox,
  ColorPicker,
  Input,
  Popconfirm,
  Segmented,
} from 'antd';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import style from './PresetsSideModal.module.css';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import PresetsSorter from '../DataPresets/PresetsSorter.jsx';
import ModeContentManager from './ModeContentManager.jsx';
import {
  $dataPresets,
  $presetAreaToEdit,
  $userPresets,
  deletePresetEv,
  resetPresetAreaToEditEv,
  savePresetEv,
  updateDataPresetEv,
} from '../../models/dataPresetsModel/index.js';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { sortDataPresets } from '../../utils/data-presets-utils.js';

export default function PresetsContent(props) {
  const { currentPage } = props;
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);
  const dataPresets = useUnit($dataPresets);
  const userPresets = useUnit($userPresets);
  const presetAreaToEdit = useUnit($presetAreaToEdit);
  const resetPresetAreaToEdit = useUnit(resetPresetAreaToEditEv);
  const updateDataPreset = useUnit(updateDataPresetEv);
  const savePreset = useUnit(savePresetEv);
  const deletePreset = useUnit(deletePresetEv);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('name');
  const [chosenPresets, setChosenPresets] = useState([]);
  const [sortedDataPresets, setSortedDataPresets] = useState([]);
  const [sortedUserPresets, setSortedUserPresets] = useState([]);
  const [color, setColor] = useState('#2D9CDB');
  const [presetName, setPresetName] = useState('');
  const [presetType, setPresetType] = useState('my');
  const [chosenPreset, setChosenPreset] = useState(null);

  const deleteChosenPresets = () => {
    deletePreset(chosenPresets);
  };

  const onSavePreset = () => {
    savePreset({
      color: color.metaColor ? `#${color.metaColor.toHexString()}` : color,
      name: presetName,
    });
    setColor('#2D9CDB');
    setPresetName('');
  };

  const resetPreset = () => {
    resetPresetAreaToEdit();
  };

  const updatePreset = () => {
    const preset = dataPresets.find(
      (item) => item.name === presetAreaToEdit.preset
    );
    updateDataPreset({
      ...preset,
    });
  };

  const segmentedOptions = [
    {
      label: ru_en_page_dictionary.my_presets[language],
      value: 'my',
    },
    {
      label: ru_en_page_dictionary.all_presets[language],
      value: 'all',
    },
  ];

  const selectAllPresets = (e) => {
    if (e.target.checked) {
      setChosenPresets(
        presetType === 'my'
          ? userPresets.map((item) => item.id)
          : dataPresets.map((item) => item.id)
      );
    } else {
      setChosenPresets([]);
    }
  };

  useEffect(() => {
    setSortedDataPresets(sortDataPresets([dataPresets, sortBy, sortOrder]));
  }, [sortBy, sortOrder, dataPresets]);

  useEffect(() => {
    setSortedUserPresets(sortDataPresets([userPresets, sortBy, sortOrder]));
  }, [sortBy, sortOrder, userPresets]);

  useEffect(() => {
    if (chosenPreset) {
      setChosenPreset(
        dataPresets.find((preset) => preset.name === chosenPreset.name)
      );
    }
  }, [dataPresets]);

  return (
    <>
      <div className={style.save_popover}>
        <ColorPicker value={color} onChange={setColor} size="large" />
        <Input
          placeholder={ru_en_page_dictionary.enter_preset_name[language]}
          value={presetName}
          onChange={(e) => setPresetName(e.target.value)}
          onPressEnter={onSavePreset}
        />
      </div>
      <div className={style.preset_buttons_wrapper}>
        <Button
          type="primary"
          className={style.preset_button}
          onClick={onSavePreset}
        >
          {ru_en_page_dictionary.save_preset[language]}
        </Button>
        {/* <Button */}
        {/*  type="primary" */}
        {/*  className={style.preset_button} */}
        {/*  disabled={!presetAreaToEdit.preset} */}
        {/*  onClick={updatePreset} */}
        {/* > */}
        {/*  {ru_en_page_dictionary.update_preset[language]} */}
        {/* </Button> */}
      </div>
      <Segmented
        options={segmentedOptions}
        block
        className={style.presets_type}
        value={presetType}
        onChange={setPresetType}
      />
      <div className={style.manipulation_wrapper}>
        <Checkbox
          onChange={selectAllPresets}
          checked={
            presetType === 'my'
              ? userPresets.every((item) => chosenPresets.includes(item.id))
              : dataPresets.every((item) => chosenPresets.includes(item.id))
          }
        >
          {ru_en_page_dictionary.select_all[language]}
        </Checkbox>
        <PresetsSorter
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          sortBy={sortBy}
          setSortBy={setSortBy}
          index={false}
        />
      </div>
      <div className={style.presets_wrapper}>
        <ModeContentManager
          allPresets={sortedDataPresets}
          userPresets={sortedUserPresets}
          chosenPreset={chosenPreset}
          language={language}
          resetPreset={resetPreset}
          darkMode={darkMode}
          currentPage={currentPage}
          chosenPresets={chosenPresets}
          setChosenPresets={setChosenPresets}
          presetType={presetType}
        />
      </div>
      <Popconfirm
        onConfirm={deleteChosenPresets}
        title={ru_en_page_dictionary.delete_chosen_presets[language]}
        okText={ru_en_page_dictionary.delete[language]}
        cancelText={ru_en_page_dictionary.cancel[language]}
        icon={
          <QuestionCircleOutlined
            style={{
              color: 'red',
            }}
          />
        }
      >
        <Button
          type="primary"
          className={style.delete_presets}
          disabled={chosenPresets.length === 0}
        >
          {ru_en_page_dictionary.delete_chosen[language]}
        </Button>
      </Popconfirm>
    </>
  );
}
