import React, { useState, useRef } from 'react';
import { useUnit } from 'effector-react';
import { Button, Image } from 'antd';
import { ArrowsAltOutlined } from '@ant-design/icons';
import Slider from 'react-slick';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import casesb2b_1 from '../../../assets/images/sections/casesb2b/casesb2b_1.png';
import casesb2b_2 from '../../../assets/images/sections/casesb2b/casesb2b_2.png';
import casesb2b_3 from '../../../assets/images/sections/casesb2b/casesb2b_3.png';
import style from '../Landing.module.css';
import useWindowSize from '../../../hooks/useWindowSize.js';
import B2B_case1 from '../../../assets/videos/casesb2b/B2B_case1.mp4';
import B2B_case2 from '../../../assets/videos/casesb2b/B2B_case2.mp4';
import B2B_case3 from '../../../assets/videos/casesb2b/B2B_case3.mp4';

function CasesB2B(props) {
  const language = useUnit($language);
  const [width, height] = useWindowSize();
  const [activeDot, setActiveDot] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    beforeChange: (prev, next) => setActiveDot(next),
    customPaging: (i) => (
      <div className={style.custom_dot}>
        {i === activeDot ? <div className={style.custom_dot_active} /> : ''}
      </div>
    ),
  };

  const casesItems = [
    {
      title: translation[language].casesb2b_1_title,
      description_1: translation[language].casesb2b_1_description_1,
      description_2: translation[language].casesb2b_1_description_2,
      description_3: translation[language].casesb2b_1_description_3,
      imgSrc: casesb2b_1,
      videoSrc: B2B_case1,
    },
    {
      title: translation[language].casesb2b_2_title,
      description_1: translation[language].casesb2b_2_description_1,
      description_2: translation[language].casesb2b_2_description_2,
      description_3: translation[language].casesb2b_2_description_3,
      imgSrc: casesb2b_2,
      videoSrc: B2B_case2,
    },
    {
      title: translation[language].casesb2b_3_title,
      description_1: translation[language].casesb2b_3_description_1,
      description_2: translation[language].casesb2b_3_description_2,
      description_3: translation[language].casesb2b_3_description_3,
      imgSrc: casesb2b_3,
      videoSrc: B2B_case3,
    },
  ];

  return (
    <div className={width <= 1200 ? '' : 'landing_container'}>
      <div className={style.casesb2b_wrapper}>
        <div className={style.casesb2b_title}>
          {translation[language].casesb2b_title}
        </div>
        {width <= 1200 ? (
          <div className={style.casesb2b_items_wrapper}>
            <Slider {...settings}>
              {casesItems.map((item, index) => {
                return <CaseItem key={`case-item-${index}`} {...item} />;
              })}
            </Slider>
          </div>
        ) : (
          <div className={style.casesb2b_items_wrapper}>
            {casesItems.map((item, index) => {
              return <CaseItem key={`case-item-${index}`} {...item} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
}

function CaseItem(props) {
  const {
    title,
    description_1,
    description_2,
    description_3,
    imgSrc,
    videoSrc,
  } = props;
  const language = useUnit($language);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const videoRef = useRef();

  const setFullScreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      // Firefox
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      // IE/Edge
      videoRef.current.msRequestFullscreen();
    }
  };

  return (
    <div className={style.casesb2b_card}>
      <div>
        <div className={style.casesb2b_card_title}>{title}</div>
        <div className={style.casesb2b_card_description}>{description_1}</div>
        <div className={style.casesb2b_card_subtitle}>
          {translation[language].casesb2b_subtitle_1}
        </div>
        <div className={style.casesb2b_card_description}>{description_2}</div>
        <div className={style.casesb2b_card_subtitle}>
          {translation[language].casesb2b_subtitle_2}
        </div>
        <div className={style.casesb2b_card_description}>{description_3}</div>
      </div>

      <div className={style.casesb2b_image_wrapper}>
        {/* <Image */}
        {/*  src={imgSrc} */}
        {/*  style={{ cursor: 'pointer' }} */}
        {/*  preview={{ */}
        {/*    visible: isPreviewVisible, */}
        {/*    onVisibleChange: (visible, prevVisible) => */}
        {/*      setPreviewVisible(visible), */}
        {/*    mask: null, */}
        {/*    toolbarRender: () => null, */}
        {/*    imageRender: (originalNode, info, image) => { */}
        {/*      return { */}
        {/*        ...originalNode, */}
        {/*        props: { */}
        {/*          ...originalNode.props, */}
        {/*          style: { */}
        {/*            ...originalNode.props.style, */}
        {/*            transform: */}
        {/*              'translate3d(0px, 0px, 0) scale3d(2.25, 2.25, 1) rotate(0deg)', */}
        {/*          }, */}
        {/*        }, */}
        {/*      }; */}
        {/*    }, */}
        {/*  }} */}
        {/* /> */}
        <video
          className={style.cases_video}
          autoPlay
          loop
          muted
          // controls
          ref={videoRef}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
        <Button
          icon={<ArrowsAltOutlined rotate={90} />}
          shape="circle"
          className={style.casesb2b_expand_button}
          // onClick={() => setPreviewVisible(!isPreviewVisible)}
          onClick={setFullScreen}
        />
      </div>
    </div>
  );
}

export default CasesB2B;
