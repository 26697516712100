import React, { useEffect, useState } from 'react';
import Header from './sections/Header.jsx';
import Promo from './sections/Promo.jsx';
import TrustedBy from './sections/TrustedBy.jsx';
import Illustration from './sections/Illustration.jsx';
import Products from './sections/Products.jsx';
import Features from './sections/Features.jsx';
import CasesB2B from './sections/CasesB2B.jsx';
import CasesB2G from './sections/CasesB2G.jsx';
import GetFullVersion from './sections/GetFullVersion.jsx';
import Request from './sections/Request.jsx';
import Footer from './sections/Footer.jsx';
import BgLines from './sections/BGLines.jsx';
import HeaderSmallMenu from './sections/HeaderSmallMenu.jsx';
import RequestModal from './sections/RequestModal.jsx';

export default function Landing() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <Header offset={offset} />
      <HeaderSmallMenu />
      <Promo offset={offset} />
      <TrustedBy />
      <Illustration />
      <Products />
      <Features />
      <CasesB2G />
      <CasesB2B />
      <GetFullVersion />
      <Request />
      <Footer />
      <BgLines />
      <RequestModal />
    </>
  );
}
