import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { Button, Card, Collapse, Tooltip } from 'antd';
import { CloseOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import style from './MapDemoHexModal.module.css';
import {
  $chosenZoom7Hexagon,
  $publicBusinessType,
  resetChosenZoom7HexagonEv,
} from '../../models/zoom7Model/index.js';
import {
  modal_values_dictionary,
  range_values_dict,
} from '../../dictionaries/public_index_dictionary.js';

const colors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#F1C40F',
  '#9B59B6',
  '#7a3f38',
];

function RangeProp({ title, total, items }) {
  return (
    <div className={style.card}>
      <div className={style.range_prop}>
        <div className={style.prop_title}>{title}</div>
        <div className={style.line}>
          {items.map((item, index) => {
            const percent = (item.value * 100) / total;
            return (
              <Tooltip title={`${Math.round(percent)}%`}>
                <div
                  className={style.line_item}
                  style={{
                    width: `${percent}%`,
                    background: colors[index],
                  }}
                />
              </Tooltip>
            );
          })}
        </div>
        <div className={style.legend_wrapper}>
          {items.map((item, index) => {
            return (
              <div className={style.legend_item}>
                <div
                  className={style.legend_badge}
                  style={{ background: colors[index] }}
                />
                <div>
                  {item.label} -{' '}
                  <div style={{ display: 'inline', fontWeight: 'bold' }}>
                    {item.value}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function MapDemoHexModal() {
  const chosenZoom7Hexagon = useUnit($chosenZoom7Hexagon);
  const publicBusinessType = useUnit($publicBusinessType);
  const resetChosenZoom7Hexagon = useUnit(resetChosenZoom7HexagonEv);
  const [col1Key, setCol1Key] = useState(['1']);
  const [col2Key, setCol2Key] = useState([]);
  const [col3Key, setCol3Key] = useState([]);

  useEffect(() => {
    if (!chosenZoom7Hexagon) {
      setCol1Key(['1']);
      setCol2Key([]);
      setCol3Key([]);
    }
  }, [chosenZoom7Hexagon]);

  return (
    <div
      className={[
        style.hex_info_modal,
        chosenZoom7Hexagon ? style.show : '',
      ].join(' ')}
    >
      <Button
        icon={<CloseOutlined />}
        className={style.close_button}
        onClick={resetChosenZoom7Hexagon}
      />
      <Collapse
        className={style.collapse}
        style={{
          marginTop: 32,
        }}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col1Key}
        onChange={(key) => setCol1Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: <label className={style.collapse_title}>Бизнес</label>,
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div className={style.prop_title}>
                    Конкуренты и смежный бизнес
                  </div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties[publicBusinessType]}
                  </div>
                </div>
                <div className={style.card}>
                  <div className={style.prop_title}>
                    Средняя стоимость аренды ком. недвижимости за кв. метр
                  </div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.price_per_sq_m_Com}
                  </div>
                </div>
                <div className={style.card}>
                  <div className={style.prop_title}>
                    Количество Бизнес Центров
                  </div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.attract_cnt_BC}
                  </div>
                </div>
                <div className={style.card}>
                  <div className={style.prop_title}>
                    Количество Торгово-развлекательных Центров
                  </div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.attract_cnt_TRC}
                  </div>
                </div>
                <div className={style.card}>
                  <div className={style.prop_title}>
                    Количество Торговых Центров
                  </div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.attract_cnt_TC}
                  </div>
                </div>
                <div />
              </div>
            ),
          },
        ]}
      />
      <Collapse
        className={style.collapse}
        style={{
          marginTop: 20,
        }}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col2Key}
        onChange={(key) => setCol2Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: <label className={style.collapse_title}>Аудитория</label>,
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div className={style.prop_title}>Население</div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.population_total}
                  </div>
                </div>
                <RangeProp
                  title="Пол"
                  total={chosenZoom7Hexagon?.properties.population_total}
                  items={[
                    {
                      label: modal_values_dictionary.population_sex_m,
                      value: chosenZoom7Hexagon?.properties.population_sex_m,
                    },
                    {
                      label: modal_values_dictionary.population_sex_w,
                      value: chosenZoom7Hexagon?.properties.population_sex_w,
                    },
                  ]}
                />
                <RangeProp
                  title="Возраст"
                  total={chosenZoom7Hexagon?.properties.population_total}
                  items={[
                    {
                      label: modal_values_dictionary.population_age_0_15,
                      value: chosenZoom7Hexagon?.properties.population_age_0_15,
                    },
                    {
                      label: modal_values_dictionary.population_age_16_25,
                      value:
                        chosenZoom7Hexagon?.properties.population_age_16_25,
                    },
                    {
                      label: modal_values_dictionary.population_age_26_35,
                      value:
                        chosenZoom7Hexagon?.properties.population_age_26_35,
                    },
                    {
                      label: modal_values_dictionary.population_age_36_45,
                      value:
                        chosenZoom7Hexagon?.properties.population_age_36_45,
                    },
                    {
                      label: modal_values_dictionary.population_age_46_60,
                      value:
                        chosenZoom7Hexagon?.properties.population_age_46_60,
                    },
                    {
                      label: modal_values_dictionary.population_age_61_100,
                      value:
                        chosenZoom7Hexagon?.properties.population_age_61_100,
                    },
                  ]}
                />
                <div className={style.card}>
                  <div className={style.prop_title}>Доход</div>
                  <div className={style.prop_value}>
                    {
                      range_values_dict[
                        chosenZoom7Hexagon?.properties.income_avg_range
                      ]
                    }
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
      <Collapse
        className={style.collapse}
        style={{
          marginTop: 20,
        }}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col3Key}
        onChange={(key) => setCol3Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: (
              <label className={style.collapse_title}>
                Доступность территории
              </label>
            ),
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div className={style.prop_title}>
                    Пешеходная связанность территорий
                  </div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.pedestrian_connect} / 10
                  </div>
                </div>
                <div className={style.card}>
                  <div className={style.prop_title}>
                    Максимальная затрудненность автомобильного движения
                  </div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.tj_lvl_max} / 10
                  </div>
                </div>
                <div className={style.card}>
                  <div className={style.prop_title}>
                    Количество маршрутов общественного транспорта
                  </div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.pt_route_cnt}
                  </div>
                </div>
                <div className={style.card}>
                  <div className={style.prop_title}>Количество парковок</div>
                  <div className={style.prop_value}>
                    {chosenZoom7Hexagon?.properties.parking_cnt}
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default MapDemoHexModal;
