import { createEffect } from 'effector';
import demo_zero_data from '../../data/demo_zero_data.json';
import publicData from '../../data/publicData.json';
import rbp_free_dict from '../../dictionaries/rbp_free_dict.json';

const zero_city_total = {
  population_total: rbp_free_dict.population_total.default_value,
  households: rbp_free_dict.households.default_value,
  income_avg_range: 1,
  competitors_cnt_B: rbp_free_dict.competitors_cnt_B.default_value,
  rent_price_range: 1,
  tj_lvl_avg: 1,
  pt_stops_cnt: rbp_free_dict.pt_stops_cnt.default_value,
  parking_place_cnt: rbp_free_dict.parking_place_cnt.default_value,
};

export const getZeroDataFx = createEffect().use(async (payload) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve({
        // city_total: demo_zero_data.city_total,
        city_total: zero_city_total,
        polygons: publicData.features,
        // polygons: demo_zero_data.polygons,
      });
    }, 1000);
  });
});
