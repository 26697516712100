import { createEffect } from 'effector';

export const submitRequestFx = createEffect().use(async (payload) => {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      console.log('payload', payload);
      return resolve(true);
    }, 1000)
  );
});
