import { Button, Popover, Radio, Tree } from 'antd';
import { useUnit } from 'effector-react';
import { DownOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import style from './BusinessTreeSelect.module.css';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { $businessTypeTree } from '../../models/businessTypeModel/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { rbp_business_types } from '../../dictionaries/rbp_dicts.js';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { $userAppointedPreset } from '../../models/dataPresetsModel/index.js';

export default function BusinessTreeSelect() {
  const language = useUnit($language);
  const businessTypeTree = useUnit($businessTypeTree);
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const { business_type } = useUnit($activeFilters);
  const darkMode = useUnit($isDarkTheme);
  const userAppointedPreset = useUnit($userAppointedPreset);

  const onCheck = (value) => {
    changeActiveFilter({
      field: 'business_type',
      // value: parseInt(nodes[1].split('business_')[1]),
      value,
    });
  };

  const formatedTreeData = useMemo(() => {
    return businessTypeTree.map((item) => {
      return {
        ...item,
        children: item.children.map((child) => {
          return {
            ...child,
            title: (
              <div>
                <Radio
                  checked={`business_${business_type}` === child.key}
                  onClick={() => onCheck(+child.key.split('business_')[1])}
                  disabled={!!userAppointedPreset}
                >
                  {child.title}
                </Radio>
              </div>
            ),
          };
        }),
      };
    });
  }, [businessTypeTree, business_type, userAppointedPreset]);

  const popoverContent = () => {
    return (
      <Tree
        style={{
          color: darkMode ? 'white' : '#717171',
          marginTop: '10px',
          width: '340px',
        }}
        showLine
        showIcon
        switcherIcon={<DownOutlined />}
        treeData={formatedTreeData}
        checkedKeys={[`business_${business_type}`]}
        defaultExpandedKeys={['business_4']}
        onCheck={onCheck}
        // multiple
        // checkable
        selectable={false}
      />
    );
  };

  return (
    <Popover content={popoverContent} trigger="click">
      <Button className={`${style.business_select} no_bg`}>
        {ru_en_page_dictionary.business_type[language]}
        {` (${
          Object.keys(rbp_business_types).filter((item) => /\d{3}/.test(item))
            .length
        }) `}
      </Button>
    </Popover>
  );
}
