import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { Button, Dropdown } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import style from '../Landing.module.css';
import header_logo from '../../../assets/images/sections/header/header_logo.svg';
import {
  $language,
  changeLanguageEv,
  changeMenuModalEv,
  changeShowRequestModalEv,
} from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import linkedin from '../../../assets/images/sections/header/linkedin.svg';
import useWindowSize from '../../../hooks/useWindowSize.js';
import header_burger from '../../../assets/images/sections/header/header_burger.svg';

function Header({ offset }) {
  const language = useUnit($language);
  const changeLanguage = useUnit(changeLanguageEv);
  const [width, height] = useWindowSize();
  const changeMenuModal = useUnit(changeMenuModalEv);
  const changeShowRequestModal = useUnit(changeShowRequestModalEv);

  const onOpen = () => {
    changeMenuModal(true);
  };

  const languageItems = [
    {
      key: 'en',
      label: 'Eng',
    },
    {
      key: 'ru',
      label: 'Рус',
    },
  ];

  if (width <= 1200) {
    if (offset > 0) {
      return (
        <>
          <div style={{ height: 70 }} />
          <div className={style.header_small_fixed}>
            <img src={header_logo} style={{ height: 25 }} />
            <img src={header_burger} onClick={onOpen} />
          </div>
        </>
      );
    }
    return (
      <div className="landing_container">
        <div className={style.header_small_wrapper}>
          <img
            src={header_logo}
            style={{
              display: 'block',
              margin: '0 auto',
            }}
          />
          <img
            src={header_burger}
            className={style.header_small_burger}
            onClick={onOpen}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="landing_container">
      <div className={style.header_wrapper}>
        <img src={header_logo} />
        <ul className={style.menu_wrapper}>
          <li>
            <a className={style.menu_item} href="#products">
              {translation[language].menu_item_1}
            </a>
          </li>
          <li>
            <a className={style.menu_item} href="#features">
              {translation[language].menu_item_2}
            </a>
          </li>
          <li>
            <a className={style.menu_item} href="#cases">
              {translation[language].menu_item_3}
            </a>
          </li>
        </ul>
        <div className={style.header_right}>
          {/* <Dropdown */}
          {/*  menu={{ */}
          {/*    items: languageItems, */}
          {/*    onClick: ({ key }) => { */}
          {/*      changeLanguage(key); */}
          {/*    }, */}
          {/*  }} */}
          {/* > */}
          {/*  <div className={style.header_language}> */}
          {/*    {language === 'en' ? 'Eng' : 'Рус'} */}
          {/*  </div> */}
          {/* </Dropdown> */}
          <a
            href="https://www.linkedin.com/company/qala-ai/posts/?feedView=all"
            target="_blank"
            style={{ height: '24px' }}
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
          <Button
            type="primary"
            className={style.header_request_button}
            icon={<RightOutlined />}
            iconPosition="end"
            onClick={changeShowRequestModal}
          >
            {translation[language].request_demo}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
