import React from 'react';
import { useUnit } from 'effector-react';
import Slider from 'react-slick';
import style from '../Landing.module.css';
import rcp_illustration from '../../../assets/images/sections/illustration/rcp_illustration.svg';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import useWindowSize from '../../../hooks/useWindowSize.js';

function Illustration(props) {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    // cssEase: 'linear',
  };

  const sliderItems = [
    {
      title: translation[language].illustration_title_1,
      description: translation[language].illustration_description_1,
    },
    {
      title: translation[language].illustration_title_2,
      description: translation[language].illustration_description_2,
    },
    {
      title: translation[language].illustration_title_3,
      description: translation[language].illustration_description_3,
    },
    {
      title: translation[language].illustration_title_4,
      description: translation[language].illustration_description_4,
    },
    {
      title: translation[language].illustration_title_5,
      description: translation[language].illustration_description_5,
    },
    {
      title: translation[language].illustration_title_6,
      description: translation[language].illustration_description_6,
    },
  ];

  return (
    <div className="landing_container">
      <div className={style.illustration_wrapper}>
        <div className={style.illustration_title}>
          {translation[language].illustration_title}
        </div>
        {/* <img src={illustration} className={style.illustration_img} /> */}
        <object
          type="image/svg+xml"
          data={rcp_illustration}
          className={style.illustration_animated}
        />
        {/* <div className={style.illustration_test} /> */}
        {width <= 1200 ? (
          <div
            className={`slider-container ${style.illustration_slider_wrapper}`}
          >
            <Slider {...settings}>
              {sliderItems.map((item, index) => {
                return (
                  <SliderItem
                    key={`illust-slider-item-${index}`}
                    title={item.title}
                    description={item.description}
                  />
                );
              })}
            </Slider>
          </div>
        ) : (
          <>
            <div
              className={style.illustration_cards_wrapper}
              style={{ top: 120 }}
            >
              <div className={style.illustration_card} style={{ marginTop: 5 }}>
                <div className={style.illustration_card_title}>
                  {translation[language].illustration_title_1}
                </div>
                <div className={style.illustration_card_description}>
                  {translation[language].illustration_description_1}
                </div>
              </div>
              <div className={style.illustration_card}>
                <div className={style.illustration_card_title}>
                  {translation[language].illustration_title_2}
                </div>
                <div className={style.illustration_card_description}>
                  {translation[language].illustration_description_2}
                </div>
              </div>
            </div>
            <div
              className={style.illustration_cards_wrapper}
              style={{ top: 381 }}
            >
              <div
                className={style.illustration_card}
                style={{
                  marginTop: 69,
                  marginLeft: 60,
                }}
              >
                <div className={style.illustration_card_title}>
                  {translation[language].illustration_title_3}
                </div>
                <div className={style.illustration_card_description}>
                  {translation[language].illustration_description_3}
                </div>
              </div>
              <div className={style.illustration_card}>
                <div className={style.illustration_card_title}>
                  {translation[language].illustration_title_4}
                </div>
                <div className={style.illustration_card_description}>
                  {translation[language].illustration_description_4}
                </div>
              </div>
            </div>
            <div
              className={style.illustration_cards_wrapper}
              style={{ top: 666 }}
            >
              <div
                className={style.illustration_card}
                style={{ marginTop: 37 }}
              >
                <div className={style.illustration_card_title}>
                  {translation[language].illustration_title_5}
                </div>
                <div className={style.illustration_card_description}>
                  {translation[language].illustration_description_5}
                </div>
              </div>
              <div className={style.illustration_card}>
                <div className={style.illustration_card_title}>
                  {translation[language].illustration_title_6}
                </div>
                <div className={style.illustration_card_description}>
                  {translation[language].illustration_description_6}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Illustration;

function SliderItem({ title, description }) {
  return (
    <div className={style.illustration_slider_card}>
      <div className={style.illustration_slider_title}>{title}</div>
      <div className={style.illustration_slider_desc}>{description}</div>
    </div>
  );
}
