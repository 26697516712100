import React from 'react';
import { Button, Popover, Radio, Space } from 'antd';
import { useUnit } from 'effector-react';
import { RightOutlined } from '@ant-design/icons';
import style from './PublicBusinessType.module.css';
import { public_index_dictionary } from '../../dictionaries/public_index_dictionary.js';
import {
  $publicBusinessType,
  changePublicBusinessTypeEv,
} from '../../models/zoom7Model/index.js';

function PublicBusinessType() {
  const publicBusinessType = useUnit($publicBusinessType);
  const changePublicBusinessType = useUnit(changePublicBusinessTypeEv);

  const onChange = (e) => {
    changePublicBusinessType(e.target.value);
  };
  const popoverContent = () => {
    return (
      <div className={style.business_options_wrapper}>
        <Radio.Group value={publicBusinessType} onChange={onChange}>
          <Space direction="vertical">
            {Object.keys(public_index_dictionary)
              .filter((item) => /competitors_cnt_B\d{1}/g.test(item))
              .map((item) => {
                return (
                  <Radio style={{ color: 'black' }} value={item}>
                    {public_index_dictionary[item]}
                  </Radio>
                );
              })}
          </Space>
        </Radio.Group>
      </div>
    );
  };

  return (
    <Popover content={popoverContent} trigger="click" placement="right">
      <Button className={`${style.public_business_type}`}>
        {public_index_dictionary[publicBusinessType]}
        <RightOutlined />
      </Button>
    </Popover>
  );
}

export default PublicBusinessType;
