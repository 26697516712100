export const translation = {
  ru: {
    menu_item_1: 'Ru placeholder',
    menu_item_2: 'Ru placeholder',
    menu_item_3: 'Ru placeholder',
    menu_item_4: 'Ru placeholder',
    request_demo: 'Ru placeholder',
    promo_title: 'Ru placeholder',
    promo_subtitle: 'Ru placeholder',
    promo_subtitle_2: 'Ru placeholder',

    trustedBy_title: 'Ru placeholder',
    products_title: 'Ru placeholder',

    product_1_title: 'Ru placeholder',
    product_1_description: 'Ru placeholder',

    product_1_tag_1: 'Ru placeholder',
    product_1_tag_2: 'Ru placeholder',
    product_1_tag_3: 'Ru placeholder',

    product_2_title: 'Ru placeholder',
    product_2_description: 'Ru placeholder',

    product_2_tag_1: 'Ru placeholder',
    product_2_tag_2: 'Ru placeholder',

    product_3_title: 'Ru placeholder',
    product_3_description: 'Ru placeholder',

    product_3_tag_1: 'Ru placeholder',
    product_3_tag_2: 'Ru placeholder',
    product_3_tag_3: 'Ru placeholder',
    product_3_tag_4: 'Ru placeholder',
    product_3_tag_5: 'Ru placeholder',

    features_title: 'Ru placeholder',

    features_1_tag: 'Ru placeholder',
    features_1_text: 'Ru placeholder',

    features_2_tag: 'Ru placeholder',
    features_2_text: 'Ru placeholder',

    features_3_tag: 'Ru placeholder',
    features_3_text: 'Ru placeholder',

    features_4_tag: 'Ru placeholder',
    features_4_text: 'Ru placeholder',

    casesb2g_title: 'Ru placeholder',

    casesb2g_subtitle_1: 'Ru placeholder',
    casesb2g_subtitle_2: 'Ru placeholder',

    casesb2g_1_title: 'Ru placeholder',
    casesb2g_1_description_1: 'Ru placeholder',

    casesb2g_1_description_2: 'Ru placeholder',
    casesb2g_1_description_3: 'Ru placeholder',

    casesb2g_2_title: 'Ru placeholder',
    casesb2g_2_description_1: 'Ru placeholder',

    casesb2g_2_description_2: 'Ru placeholder',
    casesb2g_2_description_3: 'Ru placeholder',

    casesb2g_3_title: 'Ru placeholder',
    casesb2g_3_description_1: 'Ru placeholder',

    casesb2g_3_description_2: 'Ru placeholder',

    casesb2g_3_description_3: 'Ru placeholder',

    casesb2b_title: 'Ru placeholder',

    casesb2b_subtitle_1: 'Ru placeholder',
    casesb2b_subtitle_2: 'Ru placeholder',

    casesb2b_1_title: 'Ru placeholder',
    casesb2b_1_description_1: 'Ru placeholder',

    casesb2b_1_description_2: 'Ru placeholder',
    casesb2b_1_description_3: 'Ru placeholder',

    casesb2b_2_title: 'Ru placeholder',
    casesb2b_2_description_1: 'Ru placeholder',

    casesb2b_2_description_2: 'Ru placeholder',
    casesb2b_2_description_3: 'Ru placeholder',

    casesb2b_3_title: 'Ru placeholder',
    casesb2b_3_description_1: 'Ru placeholder',

    casesb2b_3_description_2: 'Ru placeholder',

    casesb2b_3_description_3: 'Ru placeholder',

    getfullversion_title: 'Ru placeholder',
    getfullversion_description: 'Ru placeholder',

    getfullversion: 'Ru placeholder',

    request_title: 'Ru placeholder',

    request_1_title: 'Ru placeholder',
    request_1_items: 'Ru placeholder',

    request_2_title: 'Ru placeholder',

    request_2_items: 'Ru placeholder',

    request_3_title: 'Ru placeholder',
    request_3_items: 'Ru placeholder',

    request_4_title: 'Ru placeholder',
    request_4_items: 'Ru placeholder',

    request_5_title: 'Ru placeholder',
    request_5_items: 'Ru placeholder',

    leave_a_request: 'Ru placeholder',

    footer_title_1: 'Ru placeholder',
    footer_description_1: 'Ru placeholder',
    footer_description_2: 'Ru placeholder',

    footer_title_2: 'Ru placeholder',
    footer_description_3: 'Ru placeholder',
    footer_description_4: 'Ru placeholder',

    footer_title_3: 'Ru placeholder',
    footer_subtitle: 'Ru placeholder',

    illustration_title: 'Ru placeholder',
    illustration_title_1: 'Ru placeholder',
    illustration_description_1: 'Ru placeholder',

    illustration_title_2: 'Ru placeholder',
    illustration_description_2: 'Ru placeholder',

    illustration_title_3: 'Ru placeholder',
    illustration_description_3: 'Ru placeholder',

    illustration_title_4: 'Ru placeholder',
    illustration_description_4: 'Ru placeholder',

    illustration_title_5: 'Ru placeholder',
    illustration_description_5: 'Ru placeholder',

    illustration_title_6: 'Ru placeholder',
    illustration_description_6: 'Ru placeholder',

    request_modal_title: 'Ru placeholder',
    request_form_name: 'Ru placeholder',
    request_form_position: 'Ru placeholder',
    request_form_country: 'Ru placeholder',
    request_form_city: 'Ru placeholder',
    request_form_organization: 'Ru placeholder',
    request_form_email: 'Ru placeholder',
    request_form_message: 'Ru placeholder',
    request_form_send: 'Ru placeholder',

    request_placeholder_name: 'Ru placeholder',
    request_placeholder_position: 'Ru placeholder',
    request_placeholder_country: 'Ru placeholder',
    request_placeholder_city: 'Ru placeholder',
    request_placeholder_organization: 'Ru placeholder',
    request_placeholder_email: 'Ru placeholder',
    request_placeholder_message: 'Ru placeholder',

    request_success_title: 'Ru placeholder',
    request_success_subtitle: 'Ru placeholder',
    request_error_title: 'Ru placeholder',
    request_error_subtitle: 'Ru placeholder',
    request_try_again: 'Ru placeholder',

    request_validation_name: 'Ru placeholder',
    request_validation_position: 'Ru placeholder',
    request_validation_organization: 'Ru placeholder',
    request_validation_email: 'Ru placeholder',
    request_validation_valid_email: 'Ru placeholder!',
  },
  en: {
    menu_item_1: 'Products',
    menu_item_2: 'Features',
    menu_item_3: 'Cases',
    menu_item_4: 'News',
    request_demo: 'Request Demo',
    promo_title: 'City talks,</br>we translate',
    promo_subtitle:
      'Qala AI – UrbanTech company specializing in geospatial city analytics aspires to scale globally and create a distributed AI ecosystem for cities. ',
    promo_subtitle_2:
      'The index-based Resilient City Platform and Resilient Business Platform apply geospatial analytics in data-driven decision-making to provide city leaders and businesses with valuable insights.',
    trustedBy_title: 'Our Partners',
    products_title: 'Products',

    product_1_title: 'RCP (Resilient City Platform)',
    product_1_description:
      'A strategic digital tool to assess resilience, the ability of the urban environment to withstand challenges and identify current or long-term vulnerabilities.',
    product_1_tag_1: 'City Administrations',
    product_1_tag_2: 'Urban Planning',
    product_1_tag_3: 'Financial Bodies',

    product_2_title: 'RBP (Resilient Business Platform)',
    product_2_description:
      'A geo-recommendation service that helps businesses start and grow by identifying optimal locations and strategies through data-driven insights.',
    product_2_tag_1: 'Real Estate',
    product_2_tag_2: 'Retail & HoReCa',
    product_2_tag_3: 'Consulting',
    product_2_tag_4: 'Development',
    product_2_tag_5: 'Logistics',

    product_3_title: 'RCI (Resilient City Index)',
    product_3_description:
      'global index (500+ cities) for urban risk-resilience assessment, global city data intelligence tool',
    product_3_tag_1: 'City Administrations',
    product_3_tag_2: 'Urban Planning',
    product_3_tag_3: 'Financial Bodies',
    product_3_tag_4: 'Real Estate',
    product_3_tag_5: 'Retail & Transport',

    features_title: 'Features',

    features_1_tag: 'Accessibility',
    features_1_text: 'Identify the accessibility zone of a particular area',

    features_2_tag: '3D visualization ',
    features_2_text:
      'Use 3d visualization to look for anomalies and correlations in metrics',

    features_3_tag: 'Areas',
    features_3_text:
      'Сompare the chosen area across the five components of resilience',

    features_4_tag: 'Filters',
    features_4_text: 'Filter the metrics to find what you need',

    casesb2g_title: 'B2G Cases',

    casesb2g_subtitle_1: 'Why it matters',
    casesb2g_subtitle_2: 'What the city can do',

    casesb2g_1_title: 'Availability of Public Catering',
    casesb2g_1_description_1:
      'The density of public catering services available to residents and visitors.',
    casesb2g_1_description_2:
      'Concentrating commercial functions in one center provokes commuting, traffic congestion, and consequent pollutant emissions.',
    casesb2g_1_description_3:
      'Creating a polycentric urban structure relieves pressure on the city center and promotes sustainable development in peripheral areas.',

    casesb2g_2_title: 'Public Transport Movement Intensity',
    casesb2g_2_description_1:
      'The volume and frequency of public transport services provide a dynamic measure of public transport intensity.',
    casesb2g_2_description_2:
      'This indicator is crucial for evaluating the effectiveness of public transport systems and directly influences their accessibility and convenience.',
    casesb2g_2_description_3:
      'Cities prioritizing efficient and extensive public transport contribute to a more sustainable and liveable urban environment.',

    casesb2g_3_title: 'NDVI - vegetation index ',
    casesb2g_3_description_1:
      'A numerical indicator of the quality and quantity of vegetation in a given area.',
    casesb2g_3_description_2:
      'Quality of the urban environment, air pollution, and carbon footprint problems.',
    casesb2g_3_description_3:
      'Identify areas for tree planting, and monitor growth progress and health of green spaces and trees.',

    casesb2b_title: 'B2B Cases',

    casesb2b_subtitle_1: 'Why it matters',
    casesb2b_subtitle_2: 'What it includes',

    casesb2b_1_title: 'Best Location Selection',
    casesb2b_1_description_1:
      'Determine the best location for business development using the Resilience Business Index and locations and target groups profiling.',
    casesb2b_1_description_2:
      'Getting the location wrong can result in a significant loss of time and financial resources.',
    casesb2b_1_description_3:
      'Socio-demographic characterization, competitive analysis, and urban environment assessment.',

    casesb2b_2_title: 'Marketing Campaigns',
    casesb2b_2_description_1:
      'Launch new and adjust existing advertising campaigns and promotions based on socio-demographic analysis of the population',
    casesb2b_2_description_2:
      'Targeted marketing is highly effective and reduces advertising costs for businesses.',
    casesb2b_2_description_3:
      'Detailed portrait of the target audience - gender, age, income, marital status, presence of children, etc.) and their spatial distribution.',

    casesb2b_3_title: 'Revenue Forecasting',
    casesb2b_3_description_1:
      'Analyze sales and demand data from multiple locations using ML models.',
    casesb2b_3_description_2:
      'Enables companies to conduct accurate revenue planning and reduce existing costs.',
    casesb2b_3_description_3: 'ML algorithm considers more than 70 metrics.',

    getfullversion_title: 'Resilient City Index',
    getfullversion_description:
      "The 68-page report based on the case of Almaty city emphasizes city leaders' responsibility to prioritize resilience as a fundamental aspect of urban development. It highlights the direct link between a city's adaptability and long-term prosperity.",
    getfullversion: 'Get full version',

    request_title: 'FAQ',

    request_1_title: 'What is urban resilience, and why is it important?',
    request_1_items:
      'The UN Development Program (UNDP) provides the most authoritative\n' +
      'definition of resilience: "the ability of city dwellers to withstand economic, social, health, environmental, disaster and climate-related risks." The concept of urban resilience has been gaining relevance every year since the 2010s, surpassing the importance of sustainability due to the critical nature of urban risks. More and more institutions and international organizations are prioritizing research and policy documents on urban resilience.',

    request_2_title: 'What does the name Qala AI mean?',
    request_2_items:
      '"Qala is the Kazakh word for "city," reflecting our focus on urban environments. Qala AI embodies our mission to strengthen the resilience of cities and businesses by unlocking the potential of spatial analytics, urban data, and AI.',

    request_3_title: 'What is your uniqueness?',
    request_3_items:
      'Qala AI stands out as the first and only associate member and technology provider in Kazakhstan for the World Smart Sustainable Cities Organization (WeGO). Our strong relationships with city administrations enable valuable data-sharing partnerships, enhancing our urban analytics platforms. Our previous product, the multilayer tool for economic analysis (geo-analytics.kz), developed for Almaty, won the 2023 Seoul Smart City Prize, Bronze Prize, competing against 240 cities. With an extensive network of strategic partners in urban consulting and data provision, we bring a unique combination of expertise and innovation to the field of smart city technology.',

    request_4_title: 'What are the data sources?',
    request_4_items:
      'The data sources for Qala AI’s products include a variety of high-quality and diverse inputs. We utilize GIS maps and satellite data for spatial analysis, as well as detailed city infrastructure data sourced from urban planning documentation. We partner with local telecom companies and GPS providers to collect accurate location data. IoT sensors, provided by city departments and regional partners, deliver real-time environmental, traffic, and energy consumption insights. We also integrate economic data from fiscal operators to track spending patterns and business activity, providing a comprehensive view of urban decision-making.',

    request_5_title: 'What insights can we gain from the data?',
    request_5_items:
      'For city administrations, the platform provides critical insights into urban resilience, infrastructure performance, and population dynamics. City leaders can assess vulnerabilities in disaster preparedness, transportation efficiency, and resource distribution for smarter urban planning, more efficient allocation of resources, and proactive risk management. Administrations can also track long-term trends like population growth or economic development, enabling data-driven policymaking that addresses immediate needs and future challenges.\n' +
      'For businesses, the platform offers actionable data on market potential, customer behavior, and location-based opportunities. Retailers, for example, can use geospatial analytics to optimize store locations based on foot traffic patterns or demographic trends. Developers can assess the potential for growth in different districts, factoring in infrastructure, zoning regulations, and market demand. The data also enables businesses to adapt to shifting urban conditions, ensuring they stay competitive.',

    request_6_title: 'What businesses might be interested?',
    request_6_items:
      'The Resilient Business Platform benefits a wide range of industries that rely on data-driven decision-making within urban environments. Real estate developers can use the platform to assess urban growth patterns and infrastructure risks, enabling better project planning. Retailers can analyze consumer trends and optimize store locations based on geospatial data. Urban and strategic consulting firms can leverage the platform to provide data-backed insights for urban planning and business strategies. By utilizing the Resilient Business Platform, these businesses can better adapt to urban challenges and opportunities, ensuring sustainable growth and resilience.',

    request_7_title:
      'We are interested in finding the best locations for our stores. How detailed are the insights your company offers about them?',
    request_7_items:
      'Our platform provides granular insights into specific city locations utilizing various data sources, including mobile phone operators, connected devices, satellite imagery, public infrastructure, and local business activity. These analytics allow for actionable decision-making.\n' +
      'For instance, if you are examining commercial districts, we can provide detailed data on traffic patterns, peak activity times, and the types of businesses attracting the most visitors. This data can help retailers determine the best locations for expansion or optimize their services based on customer behavior.',

    request_8_title:
      'We are a leading real estate developer. How can your product help us?',
    request_8_items:
      'As a large real estate developer, Qala AI’s Resilient City Platform can significantly enhance project planning and execution. Our platform helps you identify the best locations for development by analyzing factors like population growth, land use trends, and consumer behavior.\n' +
      'For instance, the platform can pinpoint high-demand areas with proximity to public transport and thriving commercial activity, allowing you to make informed decisions on where to invest. Additionally, it can assess environmental factors such as Urban Health risks or pollution levels, ensuring your developments are both sustainable and resilient.\n' +
      'The platform advantages for real estate developers are site selection, best-use analytics, and market analysis. By providing detailed insights into foot traffic, demographic trends, and local economic activity, you can optimize property placement and anticipate future growth areas, maximizing return on investment.\n',

    request_9_title: 'How does the platform help cities grow?',
    request_9_items:
      'The Resilient City Platform from Qala AI provides urban planners with real-time geospatial data to make informed decisions on infrastructure, zoning, and resource allocation. It plays a crucial role in urban planning processes by serving as a tool for creating urban planning documentation. The platform integrates data from various sources, such as demographics, traffic, land use, and environmental factors, making it a comprehensive tool for guiding urban growth and development.\n' +
      'The clue consumers of the platform include city planning departments, transportation authorities, and environmental agencies. These departments can use the platform to optimize infrastructure, improve traffic management, enhance sustainability efforts, and ensure efficient delivery of public services. The data-driven insights enable city leaders to base their decisions on accurate, up-to-date information, ensuring that urban growth is strategic and sustainable.\n',

    request_10_title: 'Why do you use indices?',
    request_10_items:
      "At the core of Qala AI's platform solutions is a flexible system of indices and subindices that are fully customizable to meet the specific needs of cities and businesses. Each sub-index can be adapted to focus on specific factors such as infrastructure resilience, economic vitality, or environmental sustainability — allowing for more detailed analysis and targeted decision-making. This multi-dimensional approach offers a comprehensive understanding of the strengths and weaknesses across various aspects of urban resilience. \n" +
      'The process begins with the raw data collection, which is then normalized to ensure consistency across different spatial cells. This normalization allows us to compare different areas even if they vary significantly in size, population, or resources. The customized indices and sub-indices help users focus on specific aspects of city performance while maintaining the broader context of the overall index.\n',

    request_11_title: 'Is there a report format and how is it generated?',
    request_11_items:
      'Yes, our platform offers a comprehensive reporting function with a fully customizable structure. Users can generate reports tailored to their specific needs by selecting a particular zone, district, or even the entire city. You have the flexibility to choose the most relevant indicators, such as economic activity, infrastructure resilience, or environmental sustainability. Additionally, you can define the period you’re interested in, whether it’s a snapshot of the present or trends over a year.\n' +
      'Once you’ve made your selections, the platform generates a report that includes both normalized and absolute values, providing a balanced view for detailed analysis. The normalized values allow for easy comparison between different areas, while the absolute values give deeper insights that can be used for your own data analytics and decision-making processes. These reports can then be saved and exported, giving you a structured, data-rich document that supports your strategic goals.\n',

    leave_a_request: 'Leave a request',

    footer_title_1: 'Headquarters',
    footer_description_1:
      'Kazakhstan, Almaty, 87</br>Zheltoksan Street, office 25',
    footer_description_2: 'Tel. +7 706 640 55 95</br>Email: info@qala-ai.com',

    footer_title_2: 'Office Hours',
    footer_description_3: 'Monday to Friday</br>9:00 am to 5:00 pm',
    footer_description_4: 'Weekends by appointment',

    footer_title_3: 'Social Media',
    footer_subtitle: '2024 Qala.ai. All right reserved',

    illustration_title: 'Data Sources',
    illustration_title_1: 'GIS-Maps Data',
    illustration_description_1:
      'Spatial data using POIs and mapped urban infrastructure to calculate analytics and model urban processes efficiently.',

    illustration_title_2: 'Satellite Data',
    illustration_description_2:
      'Multispectral images with high vertical and horizontal resolution, providing detailed data for precise geospatial analysis and modeling.',

    illustration_title_3: 'City Infrastructure Data',
    illustration_description_3:
      "Urban planning documentation that reflects the city's unique characteristics for tailored analysis and development strategies.",

    illustration_title_4: 'Telecom & GPS',
    illustration_description_4:
      'Location data from cellular networks and GPS, provided through local partners for precise geospatial insights and analysis.',

    illustration_title_5: 'IoT-sensors data',
    illustration_description_5:
      'Real-time data from interconnected devices tracking environment, traffic, and energy use, provided through local partners and city departments.',

    illustration_title_6: 'Economic Data',
    illustration_description_6:
      'Statistical and fiscal data, including information on average transaction amounts, consumer spending patterns, and regional economic activities.',

    request_modal_title: 'Request Demo',
    request_form_name: 'Full name',
    request_form_position: 'Position',
    request_form_country: 'Country',
    request_form_city: 'City',
    request_form_organization: 'Organization Name',
    request_form_email: 'Contact email',
    request_form_message: 'Message',
    request_form_send: 'Send',

    request_placeholder_name: 'Name and Last Name',
    request_placeholder_position: 'Position',
    request_placeholder_country: 'Country',
    request_placeholder_city: 'City',
    request_placeholder_organization: 'Official name of the organization',
    request_placeholder_email: 'email@email.com',
    request_placeholder_message: 'Your message',

    request_success_title: 'Success!',
    request_success_subtitle: 'Thank you for your request.',
    request_error_title: 'Error!',
    request_error_subtitle: 'Something went wrong.',
    request_try_again: 'Try again',

    request_validation_name: 'Please enter your name!',
    request_validation_position: 'Please enter your position!',
    request_validation_organization: 'Please enter your organization!',
    request_validation_email: 'Please enter your email!',
    request_validation_valid_email: 'Please enter a valid email!',
  },
};
