import { createEffect } from 'effector';
import { api } from '../../api/axiosinstance.js';
import { temp_presets } from '../../dictionaries/temp_presets.js';
import { getGeoBuckets } from '../../utils/gradient-utils.js';

export const getDataPresetsFx = createEffect().use(async () => {
  return (await api(true).get('presets/?service=rcp')).data;
  // return new Promise((resolve) =>
  //   setTimeout(() => {
  //     return resolve(temp_presets);
  //   }, 1000)
  // );
});

export const createDataPresetFx = createEffect().use(async (payload) => {
  const response = await api(true).post('presets/', payload);
  return {
    ...payload,
    id: response.data,
  };
});

export const getDataPresetFx = createEffect().use(async (id) => {
  return (await api(true).get(`presets/${id}`)).data;
});

export const updateDataPresetFx = createEffect().use(async ({ id, data }) => {
  if (data.hexagons) delete data.hexagons;
  if (data.areasChartData) delete data.areasChartData;
  if (data.chartData) delete data.chartData;
  if (data.gradient) delete data.gradient;

  const response = await api(true).put(`presets/${id}`, data);
  if (response.status === 200) {
    return {
      ...data,
      id,
    };
  }
  return null;
});

export const deleteDataPresetFx = createEffect().use(async (id) => {
  const payload = {
    ids: Array.isArray(id) ? id : [id],
  };
  const response = await api(true).delete(`presets/`, { data: payload });
  if (response.status === 200) return id;
  return null;
});

async function getThresholds(hexagons, algorithm) {
  if (algorithm === 'JNK' && hexagons.length > 11000) {
    const payload = {
      array: hexagons.map((item) => item.properties.index_main),
      buckets: 11,
    };
    const result = (await api(true).post('utils/jenks', payload)).data.map(
      (item) => Math.floor(item)
    );
    return result;
  }
  return await getGeoBuckets(hexagons, algorithm);
}

export const getPresetThresholdFx = createEffect().use(
  async ({ data, algorithm, activeAreas, user }) => {
    const result = await Promise.all(
      data.map(async (preset) => {
        let { hexagons } = preset;
        if (activeAreas?.length > 0) {
          hexagons = hexagons.filter((hexagon) =>
            activeAreas.includes(hexagon.properties.area)
          );
        }
        if (hexagons.length === 0) hexagons = preset.hexagons;
        return {
          ...preset,
          gradient: await getThresholds(hexagons, algorithm),
        };
      })
    );

    return { result, user };
  }
);
