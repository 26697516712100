import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import ReactPlayer from 'react-player';
import style from '../Landing.module.css';
import screenshot from '../../../assets/images/sections/promo/screenshot.png';
import {
  $language,
  changeShowRequestModalEv,
} from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import promo_video_eng from '../../../assets/videos/promo/promo_video_eng.mp4';
import promo_icon from '../../../assets/images/sections/promo/promo_play_icon.svg';
import promo_icon_small from '../../../assets/images/sections/promo/promo_play_small.svg';
import useWindowSize from '../../../hooks/useWindowSize.js';

function Promo({ offset }) {
  const language = useUnit($language);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [width, height] = useWindowSize();
  const changeShowRequestModal = useUnit(changeShowRequestModalEv);
  const [showMini, setShowMini] = useState(false);
  const mainRef = useRef();
  const miniRef = useRef();
  const [seekTo, setSeekTo] = useState(null);
  const [seekByHandMain, setSeekByHandMain] = useState(true);
  const [seekByHandMini, setSeekByHandMini] = useState(true);

  const playVideo = () => {
    // videoRef.current.play();
    setVideoPlaying(true);
    setShowMini(true);
  };

  const onPlay = () => {
    setVideoPlaying(true);
  };
  const onPause = () => {
    setVideoPlaying(false);
  };
  const onEnded = () => {
    setVideoPlaying(false);
    setSeekTo(null);
  };
  const onSeek = (seconds, iam) => {
    if (iam === 'main') {
      console.log('I am triggered', iam);
      if (!seekByHandMain) {
        setSeekByHandMain(true);
        if (!videoPlaying) setVideoPlaying(true);
      } else if (seekTo !== seconds) {
        setVideoPlaying(false);
        setSeekByHandMain(false);
        setSeekByHandMini(false);
        setSeekTo(seconds);
      }
    }
    if (iam === 'mini') {
      console.log('I am triggered', iam);
      if (!seekByHandMini) {
        setSeekByHandMini(true);
        if (!videoPlaying) setVideoPlaying(true);
      } else if (seekTo !== seconds) {
        setVideoPlaying(false);
        setSeekByHandMain(false);
        setSeekByHandMini(false);
        setSeekTo(seconds);
      }
    }
  };

  useEffect(() => {
    if (offset === 0 && videoPlaying) {
      setShowMini(true);
    }
  }, [offset]);

  useEffect(() => {
    if (seekTo !== null) {
      mainRef.current.seekTo(seekTo);
      miniRef.current.seekTo(seekTo);
    }
  }, [seekTo]);

  return (
    <div className="landing_container">
      <div className={style.promo_wrapper}>
        <div className={style.promo_text_wrapper}>
          <div
            className={style.promo_title}
            dangerouslySetInnerHTML={{
              __html: translation[language].promo_title,
            }}
          />
          <div className={style.promo_subtitle}>
            {translation[language].promo_subtitle}
          </div>
          <div
            className={style.promo_subtitle}
            style={{ marginBottom: 'unset' }}
          >
            {translation[language].promo_subtitle_2}
          </div>
          <Button
            type="primary"
            className={style.promo_button}
            icon={<RightOutlined />}
            iconPosition="end"
            onClick={changeShowRequestModal}
          >
            {translation[language].request_demo}
          </Button>
        </div>
        {/* <img src={screenshot} /> */}
        <div className={style.promo_player_wrapper}>
          <ReactPlayer
            style={{
              border: '5px solid white',
              borderRadius: 20,
              overflow: 'hidden',
            }}
            width={width < 1200 ? 'calc(100% - 10px)' : 640}
            height={width < 1200 ? '100%' : 360}
            playing={videoPlaying}
            url={promo_video_eng}
            controls
            onPlay={onPlay}
            onPause={onPause}
            onSeek={(seconds) => onSeek(seconds, 'main')}
            // onSeeked
            ref={mainRef}
          />
          {/* <video */}
          {/*  className={style.promo_video} */}
          {/*  ref={videoRef} */}
          {/*  onEnded={videoEnded} */}
          {/* > */}
          {/*  <source src={promo_video_eng} type="video/mp4"/> */}
          {/* </video> */}
          {!videoPlaying && (
            <img
              src={width <= 1200 ? promo_icon_small : promo_icon}
              className={style.promo_video_play}
              onClick={playVideo}
            />
          )}
        </div>
        <Button
          type="primary"
          className={style.promo_button_small}
          icon={<RightOutlined />}
          iconPosition="end"
          onClick={changeShowRequestModal}
        >
          {translation[language].request_demo}
        </Button>
      </div>

      <div
        className={[
          style.mini_player,
          showMini && offset > 0
            ? style.mini_player_show
            : style.mini_player_hide,
        ].join(' ')}
      >
        <div
          className={style.mini_player_hide_button}
          onClick={() => setShowMini(false)}
        >
          <DownOutlined />
        </div>
        <ReactPlayer
          style={{
            borderRadius: 20,
            overflow: 'hidden',
          }}
          width={320}
          height={180}
          playing={videoPlaying}
          url={promo_video_eng}
          controls
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
          onSeek={(seconds) => onSeek(seconds, 'mini')}
          ref={miniRef}
        />
      </div>
    </div>
  );
}

export default Promo;
