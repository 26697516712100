import { Button, Drawer, Segmented } from 'antd';
import { useUnit } from 'effector-react';
import { useState } from 'react';
import { Column } from '@ant-design/charts';
import parse from 'html-react-parser';
import { CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import {
  $chosenMetric,
  $modalOpen,
  changeModalOpenEv,
} from '../../models/indexCardsModel/index.js';
import style from './SideModal.module.css';
import index_names_dymanics from '../../dictionaries/index_names_dymanics.json';
import { $language } from '../../models/authModel/index.js';
import DescriptionContent from './DescriptionContent.jsx';
import rbp_free_dict from '../../dictionaries/rbp_free_dict.json';

export default function SideModal() {
  const open = useUnit($modalOpen);
  const changeModalOpen = useUnit(changeModalOpenEv);
  const chosenMetric = useUnit($chosenMetric);
  const language = useUnit($language);
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState(0);

  const onClose = () => {
    changeModalOpen(false);
    setActiveTab(0);
  };

  const dict =
    pathname === '/rbp/public' ? rbp_free_dict : index_names_dymanics;

  return (
    <Drawer
      title={
        pathname === '/rbp/public'
          ? null
          : dict[chosenMetric][`name_${language}`]
      }
      open={open}
      onClose={onClose}
      closable={false}
      width={600}
      classNames={{
        header: style.modal_header,
        body: style.modal_body,
      }}
    >
      <DescriptionContent activeTab={activeTab} setActiveTab={setActiveTab} />
      <Button
        shape="circle"
        className={[style.close_button, style.close_button_desktop].join(' ')}
        icon={<CloseOutlined />}
        onClick={onClose}
      />
    </Drawer>
  );
}
