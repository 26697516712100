export const public_index_dictionary = {
  audience: 'Аудитория',
  business: 'Бизнес',
  availability: 'Доступность',
  attract_cnt_BC: 'Количество Бизнес Цетров',
  attract_cnt_TC: 'Количество Торговых Цетров',
  attract_cnt_TRC: 'Количество Торгово-развлекательных Цетров',
  competitors_cnt_B: 'Количество конкуретнтов',
  competitors_cnt_B1: 'Автосервисы, мойка, запчасти',
  competitors_cnt_B2: 'Алкоголь',
  competitors_cnt_B3: 'Аптеки',
  competitors_cnt_B4: 'Банки и банкоматы',
  competitors_cnt_B5: 'Бытовая техника и электроника',
  competitors_cnt_B6: 'Гостиницы и места размещения',
  competitors_cnt_B7: 'Зоомагазины и услуги для животных',
  competitors_cnt_B8: 'Кафе, рестораны и бары',
  competitors_cnt_B9: 'Книги и канцтовары',
  competitors_cnt_B10: 'Косметика и парфюмерия',
  competitors_cnt_B11: 'Магазины цветов и доставка',
  competitors_cnt_B12: 'Мебель',
  competitors_cnt_B13: 'Медицинские лаборатории',
  competitors_cnt_B14: 'Одежда и аксессуары',
  competitors_cnt_B15: 'Оптика',
  competitors_cnt_B16: 'Полиграфия и фото',
  competitors_cnt_B17: 'Продуктовый ритейл',
  competitors_cnt_B18: 'Пункты выдачи и постаматы',
  competitors_cnt_B19: 'Ремонт и строительные материалы',
  competitors_cnt_B20: 'Ремонт одежды и обуви',
  competitors_cnt_B21: 'Ремонт техники',
  competitors_cnt_B22: 'Салоны красоты',
  competitors_cnt_B23: 'Спортивные клубы',
  competitors_cnt_B24: 'Табак',
  competitors_cnt_B25: 'Товары для дома',
  competitors_cnt_B26: 'Химчистка',
  competitors_cnt_B27: 'Ювелирные изделия',
  competitors_cnt_B28: 'Юридические и нотариальные услуги',
  households: 'Количество домохозяйств',
  income_avg_range: 'Средний уровень дохода',
  parking_cnt: 'Количество парковок',
  parking_place_cnt: 'Количество парковочных мест',
  pedestrian_connect: 'Пешеходная связанность территорий',
  population_age_0_15: 'Жителей до 15 лет',
  population_age_16_25: 'Жителей от 16 до 25 лет',
  population_age_26_35: 'Жителей от 26 до 35 лет',
  population_age_36_45: 'Жителей от 36 до 45 лет',
  population_age_46_60: 'Жителей от 46 до 60 лет',
  population_age_61_100: 'Жителей от 61 года',
  population_sex_m: 'Количество мужчин',
  population_sex_w: 'Количество женщин',
  population_total: 'Численность населения',
  price_per_sq_m_Com: 'Средняя стоимость аренды ком недвижимости за кв метр',
  pt_route_cnt: 'Количество маршрутов общественного транспорта',
  pt_stops_cnt: 'Количество остановок общественного транспорта',
  rent_price_range: 'Средняя стоимость аренды коммерческой недвижимости',
  tj_lvl_avg: 'Среднесуточная затрудненность автомобильного движения',
  tj_lvl_max: 'Максимальная затрудненность автомобильного движения',
};

export const modal_values_dictionary = {
  population_sex_m: 'мужчины',
  population_sex_w: 'женщины',
  population_age_0_15: '0-15',
  population_age_16_25: '16-25',
  population_age_26_35: '26-35',
  population_age_36_45: '36-45',
  population_age_46_60: '46-60',
  population_age_61_100: '61-100',
};

export const range_values_dict = {
  1: 'низкий',
  2: 'ниже среднего',
  3: 'средний',
  4: 'выше среднего',
  5: 'высокий',
};
