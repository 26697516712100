import { createStore } from 'effector';

export const $language = createStore('en');

export const $menuModal = createStore(false);

export const $showError = createStore(false);

export const $showSuccess = createStore(false);

export const $showRequestModal = createStore(false);
