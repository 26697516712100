import { createStore } from 'effector';

export const $zoom7Hexagons = createStore([]);
$zoom7Hexagons.watch((state) => console.log('$zoom7Hexagons', state));

export const $zoom7StartHexagons = createStore([]);

export const $chosenZoom7Hexagon = createStore(null);
$chosenZoom7Hexagon.watch((state) => console.log('$chosenZoom7Hexagon', state));

export const $publicBusinessType = createStore('competitors_cnt_B1');
$publicBusinessType.watch((state) => console.log('$publicBusinessType', state));
