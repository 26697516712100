import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { Button, Form, Input, Result } from 'antd';
import TextArea from 'antd/es/input/TextArea.js';
import { CloseOutlined, UndoOutlined } from '@ant-design/icons';
import style from '../Landing.module.css';
import {
  $language,
  $showError,
  $showRequestModal,
  $showSuccess,
  changeShowRequestModalEv,
  resetShowErrorEv,
  resetShowSuccessEv,
  submitRequestEv,
  submitRequestFx,
} from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import useWindowSize from '../../../hooks/useWindowSize.js';

function RequestModal() {
  const language = useUnit($language);
  const [requestForm] = Form.useForm();
  const submit = useUnit(submitRequestEv);
  const loading = useUnit(submitRequestFx.pending);
  const showError = useUnit($showError);
  const showSuccess = useUnit($showSuccess);
  const resetShowError = useUnit(resetShowErrorEv);
  const resetShowSuccess = useUnit(resetShowSuccessEv);
  const showRequestModal = useUnit($showRequestModal);
  const changeShowRequestModal = useUnit(changeShowRequestModalEv);
  const [isOpen, setIsOpen] = useState(false);
  const [width, height] = useWindowSize();

  useEffect(() => {
    if (showRequestModal) {
      setIsOpen(showRequestModal);
    } else {
      setTimeout(() => {
        setIsOpen(showRequestModal);
      }, 500);
    }
  }, [showRequestModal]);

  const onSend = () => {
    requestForm.submit();
  };

  const closeModal = () => {
    requestForm.resetFields();
    changeShowRequestModal(false);
    resetShowError();
    resetShowSuccess();
  };

  const onRetry = () => {
    resetShowError();
    requestForm.resetFields();
  };

  let content = (
    <>
      <div className={style.request_modal_title}>
        {translation[language].request_modal_title}
      </div>
      <Form
        form={requestForm}
        layout="vertical"
        style={{ marginTop: 25 }}
        onFinish={submit}
      >
        <Form.Item
          style={{
            marginBottom: width < 1200 ? 10 : 24,
          }}
          name="name"
          rules={[
            {
              required: true,
              message: translation[language].request_validation_name,
            },
          ]}
          label={
            <label className={style.request_form_label}>
              {translation[language].request_form_name}
            </label>
          }
        >
          <Input
            className={style.request_form_input}
            placeholder={translation[language].request_placeholder_name}
          />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: width < 1200 ? 10 : 24,
          }}
          name="position"
          rules={[
            {
              required: true,
              message: translation[language].request_validation_position,
            },
          ]}
          label={
            <label className={style.request_form_label}>
              {translation[language].request_form_position}
            </label>
          }
        >
          <Input
            className={style.request_form_input}
            placeholder={translation[language].request_placeholder_position}
          />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: width < 1200 ? 10 : 24,
          }}
          name="country"
          label={
            <label className={style.request_form_label}>
              {translation[language].request_form_country}
            </label>
          }
        >
          <Input
            className={style.request_form_input}
            placeholder={translation[language].request_placeholder_country}
          />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: width < 1200 ? 10 : 24,
          }}
          name="city"
          label={
            <label className={style.request_form_label}>
              {translation[language].request_form_city}
            </label>
          }
        >
          <Input
            className={style.request_form_input}
            placeholder={translation[language].request_placeholder_city}
          />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: width < 1200 ? 10 : 24,
          }}
          name="organization"
          rules={[
            {
              required: true,
              message: translation[language].request_validation_organization,
            },
          ]}
          label={
            <label className={style.request_form_label}>
              {translation[language].request_form_organization}
            </label>
          }
        >
          <Input
            className={style.request_form_input}
            placeholder={translation[language].request_placeholder_organization}
          />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: width < 1200 ? 10 : 24,
          }}
          name="email"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: translation[language].request_validation_email,
            },
            {
              pattern: /.+@.+\..+/,
              message: translation[language].request_validation_valid_email,
            },
          ]}
          label={
            <label className={style.request_form_label}>
              {translation[language].request_form_email}
            </label>
          }
        >
          <Input
            className={style.request_form_input}
            placeholder={translation[language].request_placeholder_email}
          />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: width < 1200 ? 10 : 24,
          }}
          name="message"
          label={
            <label className={style.request_form_label}>
              {translation[language].request_form_message}
            </label>
          }
        >
          <TextArea
            rows={4}
            placeholder={translation[language].request_placeholder_message}
          />
        </Form.Item>
      </Form>
      <Button
        className={style.request_form_button}
        onClick={onSend}
        loading={loading}
      >
        {translation[language].request_form_send}
      </Button>
    </>
  );

  if (showSuccess) {
    content = (
      <Result
        status="success"
        title={
          <div className={style.request_result_title}>
            {translation[language].request_success_title}
          </div>
        }
        subTitle={
          <div className={style.request_result_subtitle}>
            {translation[language].request_success_subtitle}
          </div>
        }
      />
    );
  }
  if (showError) {
    content = (
      <Result
        status="error"
        title={
          <div className={style.request_result_title}>
            {translation[language].request_error_title}
          </div>
        }
        subTitle={
          <div className={style.request_result_subtitle}>
            {translation[language].request_error_subtitle}
          </div>
        }
        extra={[
          <Button
            key="retry_button"
            className={style.request_retry_button}
            icon={<UndoOutlined />}
            iconPosition="end"
            onClick={onRetry}
          >
            {translation[language].request_try_again}
          </Button>,
        ]}
      />
    );
  }

  return (
    <div
      className={[
        style.request_modal_wrapper,
        showRequestModal ? style.animation_appear : style.animation_disappear,
        isOpen ? style.show : style.hide,
      ].join(' ')}
    >
      <div className={style.request_modal_overlay} onClick={closeModal} />
      <div className={style.request_modal}>
        <CloseOutlined
          className={style.request_close_button}
          onClick={closeModal}
        />
        {content}
      </div>
    </div>
  );
}

export default RequestModal;
