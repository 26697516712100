import Chart from 'react-apexcharts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUnit } from 'effector-react';
import {
  InfoCircleOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import style from '../InfographicsTabs.module.css';
import {
  $isDarkTheme,
  $language,
  $userInfo,
} from '../../../models/authModel/index.js';
import index_names_dynamics from '../../../dictionaries/index_names_dymanics.json';
import {
  wsSendRBPAddFavoritesChart,
  wsSendRBPRemoveFavoritesChart,
} from '../../../utils/webSocketConfig.js';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { changeModalOpenEv } from '../../../models/indexCardsModel/index.js';
import { rbp_chart_units } from '../../../dictionaries/rbp_dicts.js';
import { formatNumber } from '../../../utils/format-utils.js';
import {
  age_dictionary,
  gender_dictionary,
  income_dictionary,
} from '../../../../dictionaries/socdem_dictionaries.js';
import {
  $chartFilters,
  changeChartFiltersEv,
} from '../../../models/activeFiltersModel/index.js';

const incomeCharts = ['106', '107'];
const working_population_titles = ['102', '104', '107'];

export default function ColumnChart(props) {
  const { data, title, expanded } = props;
  const chartRef = useRef();
  const darkMode = useUnit($isDarkTheme);
  const language = useUnit($language);
  const userFavs = useUnit($userInfo).rbp_chart_favorites;
  const changeModalOpen = useUnit(changeModalOpenEv);
  const changeChartFilters = useUnit(changeChartFiltersEv);
  const chartFilters = useUnit($chartFilters);

  const addFavorite = () => {
    wsSendRBPAddFavoritesChart([...userFavs, +title]);
  };

  const removeFavorite = () => {
    wsSendRBPRemoveFavoritesChart([+title]);
  };

  const onQuestionClick = () => {
    console.log('card_index: -', title);
    changeModalOpen(`d${title}`);
  };

  const categories = useMemo(() => {
    const result = [];
    Object.keys(data).forEach((item) => {
      Object.keys(data[item]).forEach((category) =>
        result.includes(category) ? null : result.push(category)
      );
    });
    return result;
  }, [data]);

  const handleChartClick = (event, chartContext, config) => {
    const isIncome = incomeCharts.includes(title);

    const { name } = config.config.series[config.seriesIndex];
    const genderId = Object.entries(gender_dictionary).find(
      ([key, value]) => value.en.toLowerCase() === name
    )[0];

    const category = config.config.xaxis.categories[config.dataPointIndex];
    const key = Object.entries(data[name]).find(([key, value]) =>
      category.includes(key)
    )[0];
    const dict = isIncome ? income_dictionary : age_dictionary;
    const id = Object.keys(dict).find((item) => dict[item] === key);

    const working = working_population_titles.includes(title);

    const payload = {
      working,
      gender: genderId,
      [isIncome ? 'income' : 'age']: id,
      chart: title,
    };
    changeChartFilters(payload);
  };

  const options = {
    // width: 355,
    // height: 500,
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
        },
      },
    },
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        click: handleChartClick,
        dataPointMouseEnter(event) {
          event.target.style.cursor = 'pointer';
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          const tempVal = value % 1 !== 0 ? value.toFixed(1) : value;
          return `${formatNumber(tempVal)} ${
            rbp_chart_units[title].y[language]
          }`;
        },
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
      },
    },
    xaxis: {
      categories:
        title !== '204'
          ? categories.map(
              (item) => `${item} ${rbp_chart_units[title].x[language]}`
            )
          : categories.map((item) => ru_en_page_dictionary[item][language]),
      // categories: Object.keys(data.male).map((item) => 1),
      labels: {
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
      },
    },
    dataLabels: {
      enabled: false,
      // offsetY: -20,
    },
    legend: {
      // onItemClick: {
      //   toggleDataSeries: false,
      // },
      formatter: (value) => `${ru_en_page_dictionary[value][language]}`,
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
      y: {
        title: {
          formatter: (value) => `${ru_en_page_dictionary[value][language]}`,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'lighten',
          value: 0.3,
        },
      },
    },
  };

  const series = Object.keys(data).map((item) => {
    return {
      name: item,
      data: categories.map((category) => {
        return data[item][category] || 0;
      }),
    };
  });

  useEffect(() => {
    const { selectedDataPoints } = chartRef.current.chart.w.globals;
    if (
      (chartFilters.length > 0 && chartFilters[0].chart !== title) ||
      (chartFilters.length === 0 && selectedDataPoints.length > 0)
    ) {
      // FIXME Created copy because seems like selectedDataPoints dynamically updates even while forEach with toggle iterates (#1)
      const copy = JSON.parse(JSON.stringify(selectedDataPoints));
      if (copy.length > 0) {
        copy.forEach((item, seriesIndex) => {
          if (item && item.length > 0) {
            item.forEach((dataIndex) => {
              // FIXME #1
              chartRef.current.chart.ctx.toggleDataPointSelection(
                seriesIndex,
                dataIndex
              );
            });
          }
        });
      }
    }
    if (chartFilters.length > 0 && chartFilters[0].chart === title) {
      const copy = JSON.parse(JSON.stringify(selectedDataPoints));

      const dict = Object.hasOwn(chartFilters[0], 'age')
        ? age_dictionary
        : income_dictionary;
      const field = chartFilters[0].age ? 'age' : 'income';
      const chartLabels = chartRef.current.chart.w.globals.labels;
      if (copy.length === 0) {
        chartFilters.forEach((filter) => {
          const seriesIndex = +filter.gender - 1;
          const element = chartLabels.find((item) =>
            item.includes(dict[filter[field]])
          );
          const dataPointIndex = chartLabels.indexOf(element);

          chartRef.current.chart.ctx.toggleDataPointSelection(
            seriesIndex,
            dataPointIndex
          );
        });
      }
    }
  }, [chartFilters]);

  // if (title === '204') {
  //   series = [
  //     {
  //       name: 'business_type',
  //       data: Object.values(data),
  //       // data: [5, 10, 15],
  //     },
  //   ];
  // }

  return (
    <div
      className={[
        style.chart_wrapper,
        expanded ? style.chart_expanded : '',
      ].join(' ')}
      style={{
        background: darkMode ? '#181A1F' : '#F8F8F8',
      }}
    >
      <div className={style.chart_header_wrapper}>
        <div className={style.chart_title}>
          {index_names_dynamics[`d${title}`][`name_${language}`]}
        </div>
        {userFavs.includes(+title) ? (
          <StarFilled onClick={removeFavorite} />
        ) : (
          <StarOutlined onClick={addFavorite} />
        )}
        <div
          className={style.modal_button}
          onClick={onQuestionClick}
          style={{
            color: darkMode ? 'white' : '#717171',
          }}
        >
          <InfoCircleOutlined />
        </div>
      </div>
      <Chart
        ref={chartRef}
        options={options}
        series={series}
        type="bar"
        width={500}
        height={190}
        padding="15"
      />
    </div>
  );
}
