import { sample } from 'effector';
import {
  $language,
  $menuModal,
  $showError,
  $showRequestModal,
  $showSuccess,
} from './stores.js';
import {
  changeLanguageEv,
  changeMenuModalEv,
  changeShowRequestModalEv,
  resetShowErrorEv,
  resetShowSuccessEv,
  submitRequestEv,
} from './events.js';
import { submitRequestFx } from './effects.js';

$language.on(changeLanguageEv, (_, payload) => payload);

$menuModal
  .on(changeMenuModalEv, (state, payload) => {
    if (typeof payload === 'boolean') {
      return payload;
    }
    return !state;
  })
  .on(changeShowRequestModalEv, (_, payload) => false);

$showRequestModal.on(changeShowRequestModalEv, (state, payload) => {
  if (typeof payload === 'boolean') {
    return payload;
  }
  return !state;
});

$showSuccess.reset(resetShowSuccessEv);
$showError.reset(resetShowErrorEv);

sample({
  clock: submitRequestEv,
  target: submitRequestFx,
});

sample({
  clock: submitRequestFx.doneData,
  fn: () => true,
  target: $showSuccess,
});

sample({
  clock: submitRequestFx.failData,
  fn: () => true,
  target: $showError,
});
