import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { Image } from 'antd';
import style from '../Landing.module.css';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import products_1_en from '../../../assets/images/sections/products/products_1_en.png';
import products_1_ru from '../../../assets/images/sections/products/products_1_ru.png';
import products_2_en from '../../../assets/images/sections/products/products_2_en.png';
import products_2_ru from '../../../assets/images/sections/products/products_2_ru.png';
import tag_ca from '../../../assets/images/sections/products/tag_ca.svg';
import tag_fb from '../../../assets/images/sections/products/tag_fb.svg';
import tag_re from '../../../assets/images/sections/products/tag_re.svg';
import tag_rnt from '../../../assets/images/sections/products/tag_rnt.svg';
import tag_up from '../../../assets/images/sections/products/tag_up.svg';
import tag_con from '../../../assets/images/sections/products/tag_con.svg';
import tag_dev from '../../../assets/images/sections/products/tag_dev.svg';
import useWindowSize from '../../../hooks/useWindowSize.js';
import products_1_small_en from '../../../assets/images/sections/products/products_1_small_en.png';
import products_1_small_ru from '../../../assets/images/sections/products/products_1_small_ru.png';
import products_2_small_en from '../../../assets/images/sections/products/products_2_small_en.png';
import products_2_small_ru from '../../../assets/images/sections/products/products_2_small_ru.png';

const productsImg = {
  en: {
    products_1: products_1_en,
    products_2: products_2_en,
    products_1_small: products_1_small_en,
    products_2_small: products_2_small_en,
  },
  ru: {
    products_1: products_1_ru,
    products_2: products_2_ru,
    products_1_small: products_1_small_ru,
    products_2_small: products_2_small_ru,
  },
};

function Products(props) {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  const products = [
    {
      imgSrc:
        width <= 1200
          ? productsImg[language].products_1_small
          : productsImg[language].products_1,
      title: translation[language].product_1_title,
      description: translation[language].product_1_description,
      tags: [
        {
          label: translation[language].product_1_tag_1,
          icon: tag_ca,
        },
        {
          label: translation[language].product_1_tag_2,
          icon: tag_fb,
        },
        {
          label: translation[language].product_1_tag_3,
          icon: tag_up,
        },
      ],
    },
    {
      imgSrc:
        width <= 1200
          ? productsImg[language].products_2_small
          : productsImg[language].products_2,
      title: translation[language].product_2_title,
      description: translation[language].product_2_description,
      tags: [
        {
          label: translation[language].product_2_tag_1,
          icon: tag_re,
        },
        {
          label: translation[language].product_2_tag_2,
          icon: tag_up,
        },
        {
          label: translation[language].product_2_tag_3,
          icon: tag_con,
        },
        {
          label: translation[language].product_2_tag_4,
          icon: tag_dev,
        },
        {
          label: translation[language].product_2_tag_5,
          icon: tag_rnt,
        },
      ],
    },
    // {
    //   imgSrc: products_3,
    //   title: translation[language].product_3_title,
    //   description: translation[language].product_3_description,
    //   tags: [
    //     translation[language].product_3_tag_1,
    //     translation[language].product_3_tag_2,
    //     translation[language].product_3_tag_3,
    //     translation[language].product_3_tag_4,
    //     translation[language].product_3_tag_5,
    //   ],
    // },
  ];

  return (
    <div
      className={width <= 1200 ? style.products_wrapper : 'landing_container'}
    >
      <div
        className={width <= 1200 ? '' : style.products_wrapper}
        id="products"
      >
        <div className={style.products_title}>
          {translation[language].products_title}
        </div>
        <div className={style.products_cards_wrapper}>
          {products.map((product, index) => {
            return (
              <ProductsCard
                key={`product-${index}`}
                cardIndex={index}
                {...product}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function ProductsCard(props) {
  const { imgSrc, title, description, tags, cardIndex } = props;
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [width, height] = useWindowSize();

  return (
    <div className={style.products_card}>
      <Image
        src={imgSrc}
        style={{ cursor: 'pointer' }}
        className={style.products_card_img}
        width={width < 1200 ? '100%' : 540}
        height={width < 1200 ? '100%' : 250}
        preview={{
          visible: isPreviewVisible,
          onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
          mask: null,
          toolbarRender: () => null,
        }}
      />
      <div className={style.products_card_text_wrapper}>
        <div className={style.products_card_title}>{title}</div>
        <div className={style.products_card_description}>{description}</div>
      </div>
      <div className={style.products_card_tags_wrapper}>
        {tags.map((item, index) => {
          return (
            <ProductsTag
              key={`card-${cardIndex}-tag-${index}`}
              text={item.label}
              icon={item.icon}
            />
          );
        })}
      </div>
    </div>
  );
}

function ProductsTag({ text, icon }) {
  const [width, height] = useWindowSize();

  return (
    <div className={style.products_card_tag}>
      <img src={icon} style={{ width: width <= 1200 ? 18 : 20 }} />
      {text}
    </div>
  );
}

export default Products;
