import { sample } from 'effector';
import { getZoom10HexagonsFx } from './effects.js';
import {
  $activeExtrusionProp,
  $isExtrusion,
  $zoom10Hexagons,
  $zoom10MutatedByMetric,
  $zoom10MutatedHexagons,
  $zoom10StartHexagons,
} from './stores.js';
import {
  $activeFilters,
  calculateByMetricEv,
  changeActiveFilterEv,
  resetActiveFiltersEv,
  submitFiltersEv,
  updateFiltersByPresetEv,
} from '../activeFiltersModel/index.js';
import zoom8_9_10_connections from '../../data/zoom8_9_10_connections.json';
import {
  $zoom9Hexagons,
  updateZoom9HexagonsBy10Ev,
} from '../zoom9Model/index.js';
import {
  calculateHexagonsByMetrics,
  calculateIndexesByMedian,
  calculate10Hexagons,
  formMutatedDict10,
  mutateHexagons,
  calculateByMedian,
  filterGradient,
} from '../../utils/hexagon-utils.js';
import {
  calculateExcByPresetEv,
  calculateMetricByPresetEv,
  changeActiveExtPropEv,
  filterZoom10GradientEv,
  formMutatedDict10Ev,
  putCalculated10HexagonsEv,
  putCalculatedExcByPresetEv,
  putFilteredZoom10HexagonsEv,
  putZoom10HexagonsEv,
  resetZoom10ByMetricsEv,
  toggleExtrusionEv,
} from './events.js';
import {
  $zoom8Hexagons,
  updateZoom8HexagonsBy10Ev,
} from '../zoom8Model/index.js';
import {
  $loader,
  delayedHideLoaderEv,
  hideCardsLoaderEv,
  hideLoaderEv,
  hideRadarLoaderEv,
  showCardsLoaderEv,
  showLoaderEv,
  showRadarLoaderEv,
} from '../webSocketModel/index.js';
import filterHexagons from '../../counters/hex_counter/hex_counter.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import { $isochroneStore } from '../isochroneModel/index.js';
import {
  updateCardsByZoom10Ev,
  updateChartByZoom10Ev,
} from '../indexCardsModel/index.js';
import { $userInfo, sendTimersEv } from '../authModel/index.js';
import {
  calculateThresholdsEv,
  putBlockedBucketsEv,
} from '../gradientModel/index.js';

// sample({
//   clock: getZoom10HexagonsFx.doneData,
//   fn: (clock) =>
//     clock.features.map((item) => {
//       return {
//         ...item,
//         properties: {
//           ...item.properties,
//           hex_id: item.id,
//         },
//       };
//     }),
//   target: [$zoom10Hexagons, $zoom10StartHexagons],
// });

$zoom10MutatedByMetric.reset(resetZoom10ByMetricsEv);

$isExtrusion.on(toggleExtrusionEv, (state) => {
  if (!state) {
    window.map.flyTo({
      pitch: 45,
    });
    window.map.dragRotate.enable();
  } else {
    window.map.flyTo({
      pitch: 0,
    });
    setTimeout(() => {
      window.map.rotateTo(0);
    }, 1000);
    window.map.dragRotate.disable();
  }
  return !state;
});

$activeExtrusionProp.on(changeActiveExtPropEv, (_, payload) => payload);

sample({
  clock: putZoom10HexagonsEv,
  fn: (clock) => {
    const data = mutateHexagons(clock, 'zoom_10');
    window.mutate_end = performance.now();
    return data;
  },
  target: [
    $zoom10Hexagons,
    $zoom10StartHexagons,
    sendTimersEv,
    calculateThresholdsEv,
  ],
});

sample({
  clock: putFilteredZoom10HexagonsEv,
  fn: (clock) => mutateHexagons(clock, 'zoom_10'),
  target: $zoom10Hexagons,
});

sample({
  source: [
    $zoom10StartHexagons,
    $activeFilters,
    $zoom10MutatedHexagons,
    $isochroneStore,
    $zoom10MutatedByMetric,
  ],
  clock: [
    $activeFilters.updates,
    $zoom10MutatedHexagons.updates,
    $isochroneStore.updates,
    $zoom10MutatedByMetric.updates,
  ],
  filter: ([
    startData,
    activeFilters,
    mutatedData,
    isochroneStore,
    mutatedByMetric,
  ]) => {
    return (
      (hasActiveFilters(activeFilters) ||
        mutatedData.length > 0 ||
        isochroneStore.length > 0 ||
        mutatedByMetric.length > 0) &&
      window.location.pathname !== '/rbp/public'
    );
  },
  fn: (
    [startData, activeFilters, mutatedData, isochroneStore, mutatedByMetric],
    clock
  ) => {
    let data = startData;

    if (activeFilters.chosen_metrics.length > 0 && mutatedByMetric.length > 0) {
      data = mutatedByMetric;
    } else if (
      // activeFilters.excludedIndexes.length > 0 &&
      mutatedData.length > 0
    ) {
      data = mutatedData;
    }

    if (activeFilters.zoom10_hex.id !== '') {
      return data.filter((item) => {
        return item.properties.hex_id === activeFilters.zoom10_hex.id;
      });
    }
    if (activeFilters.zoom9_hex.id !== '') {
      const zoom10_ids =
        zoom8_9_10_connections.zoom9_id[activeFilters.zoom9_hex.id];
      return data.filter((item) => {
        return zoom10_ids.includes(item.properties.hex_id);
      });
    }
    if (activeFilters.zoom8_hex.id !== '') {
      const zoom9_ids =
        zoom8_9_10_connections.zoom8_id[activeFilters.zoom8_hex.id];
      const zoom10_ids = [];
      zoom9_ids.forEach((id) => {
        zoom10_ids.push(...zoom8_9_10_connections.zoom9_id[id]);
      });

      return data.filter((item) => {
        return zoom10_ids.includes(item.properties.hex_id);
      });
    }

    if (hasActiveFilters(activeFilters) || isochroneStore.length > 0) {
      return filterHexagons(data, activeFilters, isochroneStore);
    }
    return data;
  },
  target: [$zoom10Hexagons, calculateThresholdsEv],
});

sample({
  source: [
    $zoom10StartHexagons,
    $activeFilters,
    $zoom10MutatedHexagons,
    $isochroneStore,
    $zoom10MutatedByMetric,
  ],
  clock: [
    $activeFilters.updates,
    $zoom10MutatedHexagons.updates,
    $isochroneStore.updates,
    $zoom10MutatedByMetric.updates,
  ],
  filter: ([
    startData,
    activeFilters,
    mutatedData,
    isochroneStore,
    mutatedByMetric,
  ]) => {
    return (
      !hasActiveFilters(activeFilters) &&
      mutatedData.length === 0 &&
      isochroneStore.length === 0 &&
      mutatedByMetric.length === 0
    );
  },
  fn: ([startData]) => startData,
  target: [$zoom10Hexagons, calculateThresholdsEv],
});

sample({
  source: [$activeFilters, $userInfo],
  clock: filterZoom10GradientEv,
  filter: ([activeFilters, userInfo], clock) =>
    activeFilters.gradient.length === 0 && !userInfo.useBackend,
  fn: (source, clock) => filterGradient(clock, 'zoom_10'),
  target: putBlockedBucketsEv,
});

sample({
  source: [$zoom10StartHexagons, $activeFilters, $userInfo],
  clock: submitFiltersEv,
  filter: ([source, activeFilters, userInfo], clock) =>
    Array.isArray(activeFilters.excludedIndexes) &&
    activeFilters.excludedIndexes.length > 0 &&
    !userInfo?.use_backend,
  fn: ([source, activeFilters], clock) => {
    window.indexWorker.postMessage({
      worker: 'index',
      action: 'calculate10Hexagons',
      payload: {
        zoom10Hexagons: source,
        excludedIndexes: activeFilters.excludedIndexes,
      },
    });
  },
  target: [showRadarLoaderEv, showCardsLoaderEv, showLoaderEv],
  // target: [$zoom10MutatedHexagons, formMutatedDict10Ev],
});

sample({
  // clock: $activeFilters.updates,
  source: $activeFilters,
  clock: submitFiltersEv,
  filter: (clock) => clock.excludedIndexes.length === 0,
  fn: () => [],
  target: $zoom10MutatedHexagons,
});

sample({
  source: $zoom10MutatedHexagons,
  clock: resetActiveFiltersEv,
  filter: (source) => source.length > 0,
  fn: () => [],
  target: $zoom10MutatedHexagons,
});

sample({
  clock: putCalculated10HexagonsEv,
  fn: (clock) => clock,
  target: $zoom10MutatedHexagons,
});

// sample({
//   clock: formMutatedDict10Ev,
//   fn: (clock) => formMutatedDict10(clock),
//   target: [updateZoom9HexagonsBy10Ev, updateZoom8HexagonsBy10Ev],
// });

sample({
  source: [$zoom10StartHexagons, $activeFilters],
  clock: calculateByMetricEv,
  filter: ([source, activeFilters], clock) =>
    activeFilters.chosen_metrics.length > 0,
  fn: ([startData, activeFilters], clock) =>
    calculateHexagonsByMetrics(startData, activeFilters),
  target: $zoom10MutatedByMetric,
});

sample({
  clock: $activeFilters.updates,
  filter: (clock) => clock.chosen_metrics.length === 0,
  fn: () => [],
  target: $zoom10MutatedByMetric,
});

sample({
  source: [$activeFilters, $isochroneStore, $userInfo],
  clock: $zoom10Hexagons.updates,
  filter: ([activeFilters, isochroneStore, userInfo], clock) =>
    (hasActiveFilters(activeFilters) ||
      activeFilters.excludedIndexes.length > 0 ||
      isochroneStore.length > 0) &&
    activeFilters.chosen_metrics.length === 0 &&
    !userInfo?.use_backend,
  fn: ([activeFilters, isochroneStore], clock) => {
    window.indexWorker.postMessage({
      worker: 'index',
      action: 'calculateTotalIndex',
      payload: {
        hexagons: clock.map((item) => item.properties),
        excludedIndexes: activeFilters.excludedIndexes,
        activeFilters,
      },
    });
  },
  target: [showRadarLoaderEv, showCardsLoaderEv],
});

// FIXME To test 10 zoom calculation updates
// sample({
//   source: [$activeFilters, $isochroneStore, $userInfo],
//   clock: $zoom10Hexagons.updates,
//   fn: ([activeFilters, isochroneStore], clock) =>
//     calculateIndexesByMedian(
//       clock.map((item) => item.properties),
//       activeFilters.excludedIndexes,
//       activeFilters,
//       true
//     ),
// });

// FIXME To test 8 9 calculation by median 10
// sample({
//   source: [$zoom10Hexagons, $zoom8Hexagons, $zoom9Hexagons],
//   clock: $zoom10Hexagons.updates,
//   fn: ([hex_10, hex_8, hex_9], clock) => {
//     const dict = formMutatedDict10(hex_10);
//     const calc_8 = calculateByMedian('zoom_8', hex_8, dict, []);
//     const calc_9 = calculateByMedian('zoom_9', hex_9, dict, []);
//
//     debugger;
//   },
// });

sample({
  source: $zoom10StartHexagons,
  clock: calculateExcByPresetEv,
  fn: (source, clock) => {
    window.indexWorker.postMessage({
      worker: 'index',
      action: 'calculate10HexagonsByPreset',
      payload: {
        zoom10Hexagons: source,
        excludedIndexes: clock,
      },
    });
  },
  target: [showRadarLoaderEv, showCardsLoaderEv, showLoaderEv],
});

sample({
  clock: putCalculatedExcByPresetEv,
  fn: (clock) => clock,
  target: [
    $zoom10MutatedHexagons,
    updateFiltersByPresetEv,
    hideRadarLoaderEv,
    hideCardsLoaderEv,
  ],
});

sample({
  source: $zoom10StartHexagons,
  clock: calculateMetricByPresetEv,
  fn: (startData, clock) => calculateHexagonsByMetrics(startData, clock),
  target: [$zoom10MutatedByMetric, updateFiltersByPresetEv],
});
