import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from '../Landing.module.css';
import header_logo from '../../../assets/images/sections/header/header_logo.svg';
import header_close from '../../../assets/images/sections/header/header_close.svg';
import { translation } from '../../../dictionaries/translation.js';
import linkedin from '../../../assets/images/sections/header/linkedin.svg';
import {
  $language,
  $menuModal,
  changeLanguageEv,
  changeMenuModalEv,
  changeShowRequestModalEv,
} from '../../../models/landingModel/index.js';

function HeaderSmallMenu(props) {
  const menuModal = useUnit($menuModal);
  const changeMenuModal = useUnit(changeMenuModalEv);
  const language = useUnit($language);
  const changeLanguage = useUnit(changeLanguageEv);
  const [isOpen, setIsOpen] = useState(false);
  const changeShowRequestModal = useUnit(changeShowRequestModalEv);

  const onClose = () => {
    changeMenuModal(false);
  };

  useEffect(() => {
    if (menuModal) {
      setIsOpen(menuModal);
    } else {
      setTimeout(() => {
        setIsOpen(menuModal);
      }, 500);
    }
  }, [menuModal]);

  const languageItems = [
    {
      key: 'en',
      label: 'Eng',
    },
    {
      key: 'ru',
      label: 'Рус',
    },
  ];

  return (
    <div
      className={[
        style.header_menu_modal_wrapper,
        menuModal ? style.animation_appear : style.animation_disappear,
        isOpen ? style.show : style.hide,
      ].join(' ')}
    >
      <div className={style.header_menu_overlay} onClick={onClose} />
      <div className={style.header_menu_modal}>
        <div className={style.header_menu_top}>
          <img src={header_logo} style={{ height: 25 }} />
          <img src={header_close} onClick={onClose} />
        </div>
        <ul className={style.menu_wrapper}>
          <li>
            <a className={style.menu_item} href="#products" onClick={onClose}>
              {translation[language].menu_item_1}
            </a>
          </li>
          <li>
            <a className={style.menu_item} href="#features" onClick={onClose}>
              {translation[language].menu_item_2}
            </a>
          </li>
          <li>
            <a className={style.menu_item} href="#cases" onClick={onClose}>
              {translation[language].menu_item_3}
            </a>
          </li>
        </ul>
        <div className={style.header_right}>
          {/* <Dropdown */}
          {/*  menu={{ */}
          {/*    items: languageItems, */}
          {/*    onClick: ({ key }) => { */}
          {/*      changeLanguage(key); */}
          {/*    }, */}
          {/*  }} */}
          {/* > */}
          {/*  <div className={style.header_language}> */}
          {/*    {language === 'en' ? 'Eng' : 'Рус'} */}
          {/*  </div> */}
          {/* </Dropdown> */}
          <a
            href="https://www.linkedin.com/company/qala-ai/posts/?feedView=all"
            target="_blank"
            style={{ height: '24px' }}
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
          <Button
            type="primary"
            className={style.header_request_button}
            icon={<RightOutlined />}
            iconPosition="end"
            onClick={changeShowRequestModal}
          >
            {translation[language].request_demo}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HeaderSmallMenu;
