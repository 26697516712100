import React from 'react';
import { Card, Col, Row } from 'antd';
import TopBar from '../../components/TopBar/TopBar.jsx';
import LeftBar from '../../components/LeftBar/LeftBar.jsx';
import MapBlock from '../../components/MapBlock/MapBlock.jsx';
import SideModal from '../../components/SideModal/SideModal.jsx';

function DemoMainPage() {
  return (
    <>
      <TopBar />
      <Row gutter={[20, 0]} style={{ height: '100%' }}>
        {/* <Col style={{ width: 400, zIndex: 2 }}> */}
        {/*  <LeftBar /> */}
        {/* </Col> */}
        <Col style={{ width: '100%' }}>
          <Card className="map_card" bodyStyle={{ height: '100%' }}>
            <MapBlock />
          </Card>
        </Col>
      </Row>
      <SideModal />
    </>
  );
}

export default DemoMainPage;
