import React from 'react';
import style from '../Landing.module.css';
import useWindowSize from '../../../hooks/useWindowSize.js';

function BgLines() {
  const [width, height] = useWindowSize();
  return (
    <div className={style.bg_lines_wrapper}>
      {width <= 480 ? (
        <>
          <div className={style.bg_line} />
          <div className={style.bg_line} />
          <div className={style.bg_line} />
        </>
      ) : (
        <>
          <div className={style.bg_line} />
          <div className={style.bg_line} />
          <div className={style.bg_line} />
          <div className={style.bg_line} />
          <div className={style.bg_line} />
        </>
      )}
    </div>
  );
}

export default BgLines;
