import { createEvent } from 'effector';

export const changeLanguageEv = createEvent();

export const changeMenuModalEv = createEvent();

export const submitRequestEv = createEvent();

export const resetShowSuccessEv = createEvent();

export const resetShowErrorEv = createEvent();

export const changeShowRequestModalEv = createEvent();
