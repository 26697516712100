import React from 'react';
import { useUnit } from 'effector-react';
import style from '../Landing.module.css';
import { $language } from '../../../models/landingModel/index.js';
import { translation } from '../../../dictionaries/translation.js';
import adc from '../../../assets/images/sections/trustedBy/adc.png';
import colliers from '../../../assets/images/sections/trustedBy/colliers.png';
import digitalization from '../../../assets/images/sections/trustedBy/digitalization.png';
import esg from '../../../assets/images/sections/trustedBy/esg.png';
import frontier from '../../../assets/images/sections/trustedBy/frontier.png';
import astanahub from '../../../assets/images/sections/trustedBy/astanahub.png';
import saudata from '../../../assets/images/sections/trustedBy/saudata.png';
import wego from '../../../assets/images/sections/trustedBy/wego.png';
import adc_small from '../../../assets/images/sections/trustedBy/adc_small.png';
import astanahub_small from '../../../assets/images/sections/trustedBy/astanahub_small.png';
import colliers_small from '../../../assets/images/sections/trustedBy/colliers_small.png';
import digitalization_small from '../../../assets/images/sections/trustedBy/digitalization_small.png';
import esg_small from '../../../assets/images/sections/trustedBy/esg_small.png';
import frontier_small from '../../../assets/images/sections/trustedBy/frontier_small.png';
import saudata_small from '../../../assets/images/sections/trustedBy/saudata_small.png';
import wego_small from '../../../assets/images/sections/trustedBy/wego_small.png';
import useWindowSize from '../../../hooks/useWindowSize.js';

function TrustedBy(props) {
  const langauge = useUnit($language);
  const [width, height] = useWindowSize();

  return (
    <div className="landing_container">
      <div className={style.trustedBy_wrapper}>
        <div className={style.trustedBy_title}>
          {translation[langauge].trustedBy_title}
        </div>
        <div className={style.trustedBy_items_wrapper}>
          <a href="https://colliers.kz/" target="_blank" rel="noreferrer">
            <img src={width <= 1200 ? colliers_small : colliers} />
          </a>
          <a href="https://saudata.kz/" target="_blank" rel="noreferrer">
            <img src={width <= 1200 ? saudata_small : saudata} />
          </a>
          <a href="https://astanahub.com/ru/" target="_blank" rel="noreferrer">
            <img src={width <= 1200 ? astanahub_small : astanahub} />
          </a>
          <a href="https://almatydc.kz/" target="_blank" rel="noreferrer">
            <img src={width <= 1200 ? adc_small : adc} />
          </a>
          <a href="https://we-gov.org/" target="_blank" rel="noreferrer">
            <img src={width <= 1200 ? wego_small : wego} />
          </a>
          <a
            href="https://www.digital-almaty.kz/ru"
            target="_blank"
            rel="noreferrer"
          >
            <img src={width <= 1200 ? digitalization_small : digitalization} />
          </a>
          <a href="https://esgkz.com/" target="_blank" rel="noreferrer">
            <img src={width <= 1200 ? esg_small : esg} />
          </a>
          <a href="" target="_blank">
            <img src={width <= 1200 ? frontier_small : frontier} />
          </a>
          {width <= 1200 ? (
            <>
              <div className={style.column_line} />
              <div className={style.row_line} style={{ top: 80 }} />
              <div className={style.row_line} style={{ top: 180 }} />
              <div className={style.row_line} style={{ top: 280 }} />
            </>
          ) : (
            <>
              <div className={style.row_line} />
              <div
                className={style.column_line}
                style={{
                  left: '289px',
                }}
              />
              <div
                className={style.column_line}
                style={{
                  left: '590px',
                }}
              />
              <div
                className={style.column_line}
                style={{
                  left: '892px',
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TrustedBy;
