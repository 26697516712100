import { ConfigProvider, Spin } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import ContentContainer from './components/ContentContainter/ContentContainer.jsx';
import { wsGetAlmatyPolygons, wsGetRBPData } from './utils/webSocketConfig.js';
import { $loader, $webSocketConnected } from './models/webSocketModel/index.js';
import {
  $authStatus,
  $isDarkTheme,
  $userInfo,
  $userList,
  getUserListFx,
  setAuthStatusEv,
} from './models/authModel/index.js';
import { cookies } from './api/axiosinstance.js';
import { darkTheme, lightTheme } from './themeConfigs/themeConfigs.js';
import { $zoom10Hexagons } from './models/zoom10Model/index.js';
import { $zoom9Hexagons } from './models/zoom9Model/index.js';
import { getUserAppointedPresetFx } from './models/dataPresetsModel/index.js';
import { getZeroDataFx } from '../models/zoom7Model/index.js';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const webSocketConnected = useUnit($webSocketConnected);
  const showLoader = useUnit($loader);
  const authStatus = useUnit($authStatus);
  const setAuth = useUnit(setAuthStatusEv);
  const isDarkTheme = useUnit($isDarkTheme);
  const zoom9hexagons = useUnit($zoom9Hexagons);
  const getUserList = useUnit(getUserListFx);
  const userList = useUnit($userList);
  const userInfo = useUnit($userInfo);

  useEffect(() => {
    if (!location.pathname.includes('rbp')) {
      navigate('/rbp/root');
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/rbp/public') {
      getZeroDataFx();
    } else if (webSocketConnected && Object.keys(userInfo).length > 0) {
      if (zoom9hexagons.length === 0 && location.pathname === '/rbp/root') {
        if (userInfo.use_rbp_preset) {
          getUserAppointedPresetFx(userInfo.presets.rbp[0].id); // FIXME id of appointed preset form user
        } else {
          wsGetRBPData();
          window.download_start = performance.now();
        }
      }
      if (location.pathname === '/rbp/user-list' && userList.length === 0) {
        getUserList();
      }
      // console.time('TIME:: zoom 8 polygons_v2');
      // console.time('TIME:: zoom 9 polygons_v2');
      // console.time('TIME:: download zoom 10 polygons');
      // wsGetAlmatyPolygonsV2();
    }
  }, [webSocketConnected, zoom9hexagons, location, userInfo]);

  useEffect(() => {
    if (
      !authStatus &&
      location.pathname !== '/rbp/public' &&
      location.pathname !== '/rbp/chat' &&
      location.pathname !== '/rbp/chat-v2'
    ) {
      const token = cookies.get('access_token');
      if (!token) {
        navigate('/login');
      } else {
        setAuth(true);
      }
    }
  }, [authStatus, location]);

  return (
    <ConfigProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <Spin spinning={showLoader} tip="Загружаем данные..." size="large">
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </Spin>
    </ConfigProvider>
  );
}

export default App;
